<div class="popup" [ngStyle]="{width: data.width}">
  <div class="popup__content">
        <div class="popup__content-icon">
          <img src="assets/icons/ic-alert.svg" alt="error">
        </div>

        <div class="popup__content-text">
          <div>
            {{ 'mailing.page.new.confirm.delete_text' | translate }}
          </div>
          <div>
            {{ 'mailing.page.new.confirm.delete_info' | translate }}
          </div>
        </div>
  </div>
  <div class="popup__actions">
    <button 
      mat-raised-button
      color="warn-inverse"
      class="popup-actions__button"
      (click)="onDelete()"
    >
      {{ 'mailing.page.new.confirm.button.delete' | translate }}
    </button>
  </div>
</div>