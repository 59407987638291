import { Component, OnInit } from "@angular/core";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { combineLatest, Observable } from "rxjs";
import { distinctUntilChanged, filter, map, withLatestFrom } from "rxjs/operators";
import { StoreService } from "../../service/video-store/store.service";

interface SliderEvent {
  value: number
}

enum VolumeIconUrlEnum {
  mute = 'assets/icons/plyr-muted.svg',
  unmute = 'assets/icons/plyr-volume.svg',
}

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video-volume-control',
  templateUrl: './volume-control.html',
  styleUrls: ['./volume-control.scss']
})

export class RdVideoVolumeControlComponent implements OnInit {
  volume = 0;

  iconUlr$ =
    combineLatest([
      this.storeService.selectPlayerIsMuted$(),
      this.isVolumeZero$()
    ])
      .pipe(
        map(([isMuted, isVolumeZero]) => {
          if (isMuted || isVolumeZero) {
            return VolumeIconUrlEnum.mute
          }
          return VolumeIconUrlEnum.unmute
        })
      );

  constructor(private storeService: StoreService) { }

  ngOnInit(): void {
    this.isVolumeZero$()
      .pipe(takeUntilDestroyed(this))
      .subscribe(value => {
        this.storeService.fireMutedChange(value);
      });

    this.storeService.selectPlayerVolume$()
      .pipe(
        withLatestFrom(this.storeService.selectPlayerIsMuted$()),
        filter(([volume, isMuted]) => volume > 0 && isMuted),
        takeUntilDestroyed(this)
      )
      .subscribe(() => {
        this.storeService.fireMutedChange(false);
      });

    this.storeService.selectPlayerIsMuted$()
      .pipe(
        withLatestFrom(this.storeService.selectPlayerVolume$()),
        filter(([isMuted, volume]) => !isMuted && volume === 0),
        takeUntilDestroyed(this)
      )
      .subscribe(() => {
        this.storeService.fireVolumeChange(0.5);
      });

    combineLatest([
      this.storeService.selectPlayerVolume$(),
      this.storeService.selectPlayerIsMuted$()
    ])
      .pipe(takeUntilDestroyed(this))
      .subscribe(([volume, isMuted]) => {
        if (isMuted) {
          this.volume = 0;
        }
        this.volume = volume;
      });
  }

  onControlClick(): void {
    const currentIsMuted = this.storeService.getPlayerIsMuted();
    const isMuted = !currentIsMuted
    this.storeService.fireMutedChange(isMuted);
  }

  handleChange(event: SliderEvent): void {
    const currentVolume = this.storeService.getPlayerVolume();
    if(currentVolume !== event.value){
      this.storeService.fireVolumeChange(event.value)
    }
  }

  private isVolumeZero$(): Observable<boolean> {
    return this.storeService.selectPlayerVolume$()
      .pipe(
        map(volume => volume === 0),
        distinctUntilChanged()
      )
  }
}
