import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, queueScheduler, Subject } from "rxjs";
import { distinctUntilChanged, filter, map, observeOn, pluck, take, tap } from "rxjs/operators";

interface StoreUpdateReducer<TState> {
  (state: TState): TState;
}

interface StoreEvent<TEvents, TEvent extends keyof TEvents = keyof TEvents> {
  type: TEvent;
  data?: TEvents[TEvent];
}

interface StoreSelector<TState, Value> {
  (state: TState): Value;
}

@Injectable({ providedIn: 'root' })

export class Store<TState, TEvents = void> {
  private state: BehaviorSubject<TState>;
  private events: Subject<StoreEvent<TEvents>>;

  init(initialState: TState = {} as TState): void {
    this.events = new Subject<StoreEvent<TEvents>>();
    this.state = new BehaviorSubject<TState>(initialState);
  }

  get<A extends keyof TState>(...path: [A]): TState[A];
  get(path: string): unknown {
    const value = this.state.getValue();
    return value[path];
  }

  on<TEvent extends keyof TEvents>(type: TEvent): Observable<TEvents[TEvent]> {
    return this.events.asObservable().pipe(
      filter((event): event is StoreEvent<TEvents, TEvent> => event.type === type),
      map(event => event.data!),
    );
  }

  select(): Observable<TState>;
  select<A extends keyof TState>(...path: [A]): Observable<TState[A]>;
  select<A extends keyof TState, B extends keyof TState[A]>(...path: [A, B]): Observable<TState[A][B]>;
  select<A extends keyof TState, B extends keyof TState[A], C extends keyof TState[A][B]>(
    ...path: [A, B, C]
  ): Observable<TState[A][B][C]>;
  select<
    A extends keyof TState,
    B extends keyof TState[A],
    C extends keyof TState[A][B],
    D extends keyof TState[A][B][C],
  >(...path: [A, B, C, D]): Observable<TState[A][B][C][D]>;
  select<
    A extends keyof TState,
    B extends keyof TState[A],
    C extends keyof TState[A][B],
    D extends keyof TState[A][B][C],
    E extends keyof TState[A][B][C][D],
  >(...path: [A, B, C, D, E]): Observable<TState[A][B][C][D][E]>;
  select(pathOrSelector?: string, ...path: string[]): Observable<unknown> {
    return this.state.asObservable()
      .pipe(
        pluck(...([pathOrSelector, ...path] as string[])),
        filter(data => data !== undefined),
        distinctUntilChanged()
      );
  }

  fire<TEvent extends keyof TEvents>(type: TEvent, data?: TEvents[TEvent]): void {
    this.events.next({ type, data });
  }

  setField<T extends keyof TState>(fieldName: T, filedValue: TState[T], queue: boolean = false): void {
    this.update(state => ({ ...state, [fieldName]: filedValue }), queue);
  }

  update(reducer: StoreUpdateReducer<TState>, queue: boolean): void {
    this.state
      .pipe(
        queue ? observeOn(queueScheduler) : tap(),
        take(1)
      )
      .subscribe(state => {
        const newState = reducer(state);
        this.state.next(newState);
      });
  }
}