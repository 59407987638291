import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@app/store/reducers';
import { MailingBatchesResponse } from '@app/views/abonents/models/mailing/mailing-response.model';
import { getMailingBatches, getMailingBatchesFailure } from '@app/views/abonents/store/states/mailing.state';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MailingStatus } from '@app/views/abonents/models/mailing/mailing-status.model';
import { MailingHelperService } from '@app/views/abonents/services/mailing-helper.service';
import { ResolutionService } from '@app/shared/entities/common/mobile-query/mobile-query.service';
import { GetMailingBatches } from '@app/views/abonents/store/actions/mailing.actions';

@Component({
  selector: 'app-mailing-status',
  templateUrl: './mailing-status.component.html',
  styleUrls: ['./mailing-status.component.scss']
})
export class MailingStatusComponent implements OnInit {
  @Input() mailingSender: string;
  @Input() recipientsCount: number;
  @Input() startTime: number;
  @Input() createdTime: number;

  get mailingId() {
    return this._mailingId;
  }

  @Input()
  set mailingId(id: number) {
    this._mailingId = id;
    this.isDataReady = false;
    this.processedPercentage = null;
    this.store.dispatch(new GetMailingBatches(id, false));
  }

  @Output() canDeleteMailing: EventEmitter<boolean> = new EventEmitter();

  mailingStartDate: number | null;
  mailingEndDate: number | null;
  totalProcessedMessages: number | null;
  successMessages: number | null;
  failedMessages: number | null;
  mailingStatus: MailingStatus;
  status = MailingStatus;

  mailingStatusTranslation: string;
  processedPercentage: number | null;
  chipColorClass: string = 'grey-chip';
  isDataReady: boolean = false;
  tooltipStyleClass: string;

  private _mailingId: number;
  private destroy$ = new Subject<void>();
  private readonly successfullText = 'Messages processed successfully: ';
  private readonly lgTooltipSize = 'tooltip-wrapper-size-350';

  constructor(
    private store: Store<State>,
    private translate: TranslateService,
    private mailingService: MailingHelperService, 
    private resolutionService: ResolutionService,
  ) {}

  ngOnInit() {
    this.enableStoreListeners();
    this.tooltipStyleClass = this.resolutionService.isMobile ? '' : this.lgTooltipSize;
  }

  dispatchGetBatches(id: number): void {
    this.isDataReady = false;
    this.processedPercentage = null;
    this.store.dispatch(new GetMailingBatches(id, true));
  }

  private enableStoreListeners(): void {
    this.store.select(getMailingBatches).pipe(takeUntil(this.destroy$))
    .subscribe(batches => {
      if (!batches) {
        return;
      }

      this.clearMailingData();

      this.setMailingDatesInfo(batches, this.startTime, this.createdTime);
      this.calculateProcessedPercentage(batches);
      this.setProcessedDataText(batches);
      this.setMailingStatus(this.processedPercentage);
      this.checkMailingDeletion(batches, this.createdTime, this.startTime);
      this.chipColorClass = this.getChipColorClass(this.mailingStatus);

      this.isDataReady = true;
    }
  );

  this.store.select(getMailingBatchesFailure).pipe(takeUntil(this.destroy$))
    .subscribe((value) => {
      if (value) {
        this.clearMailingData();
        this.mailingStatus = MailingStatus.CREATED;
        this.mailingStatusTranslation = this.translate.instant('mailing.page.new.field.status_created');
        this.processedPercentage = 0;
        this.mailingSender = '';
        this.isDataReady = true;
      }
    })
  }

  private setMailingDatesInfo(
    batches: MailingBatchesResponse[],
    startTime: number | null,
    createdTime: number | undefined
  ): void {
    if (!createdTime) {
      return;
    }

    if (startTime === null) {
      this.mailingStartDate = createdTime;
    }

    if (startTime) {
      this.mailingStartDate = startTime;
    }

    batches.forEach(batch => {
      this.mailingEndDate = Math.max(batch.processedAt ?? batch.scheduledAt, this.mailingEndDate ?? 0);
    });
  }

  private setProcessedDataText(batches: MailingBatchesResponse[]) {
    this.successMessages = batches.reduce((sum, batch) =>
      sum + this.getNumber(batch, batch.processingDetails ? this.successfullText.length : 0, true),
    0);

    this.failedMessages = batches.reduce((sum, batch) =>
      sum + this.getNumber(batch, batch.processingDetails ? batch.processingDetails.length - 1 : 0, false),
    0);

    this.totalProcessedMessages = this.successMessages + this.failedMessages;
  }

  private calculateProcessedPercentage(batches: MailingBatchesResponse[]): void {
    const processedBatches = batches.reduce((sum, batch) => sum + (batch.processedAt ? 1 : 0), 0);
    this.processedPercentage = Math.round(processedBatches * 100 / batches.length);
  }

  private setMailingStatus(processedPercentage: number): void {
    if (processedPercentage === 100) {
      this.mailingStatus = MailingStatus.COMPLETED;
      this.mailingStatusTranslation = this.translate.instant('mailing.page.new.field.status_complete');
      return;
    }

    if (processedPercentage > 0) {
      this.mailingStatus = MailingStatus.INPROGRESS;
      this.mailingStatusTranslation = this.translate.instant('mailing.page.new.field.status_in_progress');
      return;
    }

    this.mailingStatus = MailingStatus.CREATED;
    this.mailingStatusTranslation = this.translate.instant('mailing.page.new.field.status_created');
  }

  private checkMailingDeletion(
    batches: MailingBatchesResponse[],
    createdTime: number | undefined,
    startTime: number | undefined
  ): void {
    if (!createdTime || startTime === undefined) {
      return;
    }

    if (startTime) {
      const currentTime: number = this.mailingService.getUtcTimestamp(this.mailingService.getCurrentUtcDate());
      const shouldBeDelete = batches[0]?.scheduledAt > createdTime + 60000 * 2;

      if (
        startTime > currentTime &&
        this.processedPercentage <= 0 &&
        this.mailingStatus === MailingStatus.CREATED &&
        shouldBeDelete
      ) {
        this.canDeleteMailing.emit(true);
        return;
      }
    }

    this.canDeleteMailing.emit(false);
  }

  private getNumber(batch: MailingBatchesResponse, startIndex: number, isSuccess: boolean): number {
    if (!batch.processingDetails) {
      return 0;
    }

    let numbers = '';
    const sign = isSuccess ? 1 : -1;

    while(isFinite(+batch.processingDetails[startIndex])) {
      numbers = isSuccess ?
        numbers + batch.processingDetails[startIndex] :
        batch.processingDetails[startIndex] + numbers;

      startIndex = startIndex + sign;
    }

    return +numbers;
  }

  private getChipColorClass(mailingStatus: MailingStatus | undefined): string {
    if (mailingStatus === MailingStatus.INPROGRESS) {
      return 'orange-chip';
    }

    if (mailingStatus === MailingStatus.COMPLETED) {
      return 'green-chip';
    }

    return 'grey-chip';
  }

  private clearMailingData() {
    this.mailingStartDate = null;
    this.mailingEndDate = null;
    this.processedPercentage = null;
    this.successMessages = null;
    this.failedMessages = null;
    this.totalProcessedMessages = null;
  }
}
