import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';

import { SnackbarService } from '@app/shared/components';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { PhonesUtils } from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers';
import { DialogWrapperData } from '@app/shared/ui';
import { CompanyDataResponse, UpdateCompanyRequest } from '@app/views/company/store/company-page.state';
import { CompanyPageStore } from '@app/views/company/store/company-page.store';
import { BannerAlertPopupBody } from './banner-alert-popup/banner-alert-popup-body.model';
import { BannerAlertPopupComponent } from './banner-alert-popup/banner-alert-popup.component';
import { TranslateService } from '@ngx-translate/core';

const LOGO_WIDTH = 1029;
const LOGO_HEIGHT = 75;
@Component({
  selector: 'app-company-settings-form',
  templateUrl: './company-settings-form.component.html',
  styleUrls: ['./company-settings-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanySettingsFormComponent implements OnInit, AfterViewInit {
  public logo: string | SafeUrl;
  public form: UntypedFormGroup;
  public errorText: string;
  public file: File;

  @Input() company: Partial<CompanyDataResponse>;
  @ViewChild('logo') logoRef: ElementRef;
  @ViewChild('logoMobile') logoMobileRef: ElementRef;
  @ViewChild('logoDesktop') logoDesktopRef: ElementRef;

  @Output() private toggleEdit: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private cd: ChangeDetectorRef,
    private snackbar: SnackbarService,
    private store: CompanyPageStore,
    private renderer: Renderer2,
    private dialog: MatDialog,
    private resolutionService: ResolutionService,
    private translate: TranslateService
  ) { }

  public get hasBannerPrice(): boolean {
    return this.company.bannerSum > 0;
  }

  public get hasBanner(): boolean {
    return !!this.company.banner;
  }


  public get showBannerAlert(): boolean {
    return this.file && !this.hasBanner && this.hasBannerPrice;
  }

  ngAfterViewInit(): void {
    if (this.company.banner) {
      this.renderer.setStyle(this.logoRef.nativeElement, 'backgroundImage', `url(data:image/png;base64,${this.company.banner})`);
      this.renderer.setStyle(this.logoMobileRef.nativeElement, 'backgroundImage', `url(data:image/png;base64,${this.company.banner})`);
      this.renderer.setStyle(this.logoDesktopRef.nativeElement, 'backgroundImage', `url(data:image/png;base64,${this.company.banner})`);
    }
  }
  public ngOnInit(): void {
    const supportPhone = this.company.supportPhone ? this.company.supportPhone.substring(1) : null;
    this.form = new UntypedFormGroup({
      brandName: new UntypedFormControl(this.company.brandName),
      paymentLink: new UntypedFormControl(this.company.paymentLink),
      personalAccountLink: new UntypedFormControl(this.company.personalAccountLink),
      supportChatEnabled: new UntypedFormControl(this.company.supportChatEnabled),
      supportPhone: new UntypedFormGroup({
        number: new UntypedFormControl(PhonesUtils.phoneNumber(supportPhone)),
        prefix: new UntypedFormControl(null)
      }),
      /* Убрал до выяснения подробностей
       autoSignOffer: new FormControl(this.company.autoSignOffer),
       autoSignRequireContract: new FormControl(this.company.autoSignRequireContract)
       */
    });

  }

  public onToggleEdit(): void {
    this.toggleEdit.emit(false);
  }

  public onEmitReadData(files): void {
    const reader = new FileReader();
    const file = files[0];
    const { type } = file;

    if (!this.isPng(type)) {
      this.snackbar.showMessage(this.translate.instant('company.about.page.settings.form.message.image.invalid.format'), 'error');
      return;
    }
    reader.readAsDataURL(file);

    reader.onload = () => {
      const image = new Image();

      image.src = reader.result as string;
      image.onload = () => {

        const h = image.naturalHeight;
        const w = image.naturalWidth;

        if (h <= LOGO_HEIGHT && w === LOGO_WIDTH) {
          this.renderer.setStyle(this.logoRef.nativeElement, 'backgroundImage', `url(${URL.createObjectURL(file)})`);
          this.renderer.setStyle(this.logoMobileRef.nativeElement, 'backgroundImage', `url(${URL.createObjectURL(file)})`);
          this.renderer.setStyle(this.logoDesktopRef.nativeElement, 'backgroundImage', `url(${URL.createObjectURL(file)})`);

          this.file = file;

          this.cd.markForCheck();
        } else {
          this.snackbar.showMessage(this.translate.instant('company.about.page.settings.form.message.image.invalid.size'), 'error');
        }
      };
    };

  }

  public showConfirmBanner() {
    const data: DialogWrapperData<BannerAlertPopupBody, null> = {
      title: this.translate.instant('company.about.page.settings.form.confirm.title'),
      componentName: 'ConfirmBanner',
      body: {
        bannerSum: this.company.bannerSum
      },

      submit: () => this.onSubmit(),
    };
    this.dialog.open(BannerAlertPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolutionService.isMobile ? '100%' : '500px',
      data
    }
    );
  }

  public onSubmit() {
    const {paymentLink, personalAccountLink} = this.prepereLinks();
    this.form.patchValue({ paymentLink, personalAccountLink });

    let supportPhone = null;
    const phone = this.form.getRawValue().supportPhone;
    if (phone.number) {
      supportPhone = PhonesUtils.toString(phone, true);
    }

    const payload: UpdateCompanyRequest = {
      company: { ...this.form.getRawValue(), supportPhone },
      image: this.file
    };

    this.store.updateCompanyInfo(payload);
    this.toggleEdit.emit(false);
  }

  private isPng(type: string) {
    return type.includes('png');
  }

  private prepereLinks(): {paymentLink: string | null; personalAccountLink: string | null} {
    const paymentLink = this.form.getRawValue().paymentLink;
    const personalAccountLink = this.form.getRawValue().personalAccountLink;

    return {
      paymentLink: paymentLink ? this.ensureHttps(paymentLink) : null,
      personalAccountLink: personalAccountLink ? this.ensureHttps(personalAccountLink) : null
    };
  }

  private ensureHttps(url: string): string {
    try {
      const regex =  /^(.*):\/\/(.*)$/;
      const match = url.match(regex);

      if (match) {
        const protocol = match[1];
        const restOfUrl = match[2];

        if (protocol === 'https') {
          return url;
        } else {
          return `https://${restOfUrl}`;
        }
      } else {
        return `https://${url}`;
      }

    } catch (error) {
      this.snackbar.showMessage(this.translate.instant('company.about.page.settings.form.message.link.invalid_format'), 'info');
      return null;
    }
  }
}
