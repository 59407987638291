import { createSelector } from '@ngrx/store';
import { AbonentsModuleState } from '../reducers/reducers';
import { abonentsModuleSelector } from './abonents-module.state';

const getMailingState = createSelector(
  abonentsModuleSelector,
  (state: AbonentsModuleState) => state.mailing
);

export const mailingState = createSelector(
  getMailingState,
  state => state
);

export const getMailingsStateSuccess = createSelector(
  getMailingState,
  state => ({ mailings: state.mailings, totalCount: state.totalCount })
);

export const sendMailingStateSuccess = createSelector(
  getMailingState,
  state => state.sendedMailing
);

export const mailingStateFailure = createSelector(
  getMailingState,
  state => state.error
);

export const getMailingsStateLoading = createSelector(
  getMailingState,
  state => state.mailingsLoading
);

export const mailingStateLoading = createSelector(
  getMailingState,
  state => state.mailingLoading
);

export const getMailingBatches = createSelector(
  getMailingState, 
  state => state.mailingBatches
)

export const getMailingBatchesFailure = createSelector(
  getMailingState, 
  state => state.getMailingBatchesError
)


export const getDeleteMailingSuccess = createSelector(
  getMailingState, 
  state => state.deleteMailingSuccess
)