import { AddressCriteriaResponse } from './address-criteria-response.model';

export interface MailingResponse {
  id: number;
  message: string;
  deliveryMethod: 'push' | 'sms' | 'push_or_sms';
  user: string;
  addressCriteria: AddressCriteriaResponse[];
  recipientsCount: number;
  providers: string[];
  createdAt: number;
  startTime: number;
}

export interface MailingBatchesResponse {
  id: number, 
  createdAt: number, 
  scheduledAt: number, 
  processedAt: number, 
  processingDetails: string
}
