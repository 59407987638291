export enum MailingStatus {
  CREATED = 'CREATED',
  SCHEDULED = 'SCHEDULED', 
  INPROGRESS = 'INPROGRESS', 
  CANCELLED = 'CANCELLED', 
  STOPPED = 'STOPPED', 
  COMPLETED = 'COMPLETED'
}

export type ProgressBarClass = 'green-progress-bar' | 'orange-progress-bar' | 'grey-progress-bar';
export type ChipClass = 'green-chip' | 'orange-chip' | 'grey-chip';