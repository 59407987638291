export enum ProtocolTypes {
  Metacom = 36,
  MetacomWithManyPanels = 136,
  BEWARD = 200,
  BAS_IP,
  QTECH,
  Sputnik = 206,
  Akuvox = 212,
  Rubetek = 213,
  Sokol = 207,
  Hikvision = 208,
  Rubitek = 209,
  SipCda = 215,
  UfanetSecretTop = 216
}
