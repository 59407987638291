import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Navigation, Router } from '@angular/router';
import { TopBarService } from '@app/core/services';
import { SnackbarService } from '@app/shared/components/snackbar';
import { LoaderService, RdeaDate, ResolutionService } from '@app/shared/entities/common';
import { Constants, parseError } from '@app/shared/helpers';
import { EditPopupComponentData } from '@app/shared/models';
import { AddressFormatter } from '@app/shared/services';
import { ClearAddress } from '@app/shared/store/actions/address.actions';
import { GetCitiesList, GetEntrancesList, GetHousesList, GetStreetsList } from '@app/shared/store/actions/index';
import { getCitiesStateSuccess, getEntrancesStateSuccess, getHousesStateSuccess, getStreetsStateSuccess } from '@app/shared/store/states/index';
import { EditPopupComponent } from '@app/shared/templates';
import { State } from '@app/store/reducers';
import { AddressCriteriaRequest, MailingRequest, MailingResponse } from '@app/views/abonents/models';
import { SendMailing, SendMailingClear } from '@app/views/abonents/store/actions';
import { mailingStateFailure, sendMailingStateSuccess } from '@app/views/abonents/store/states';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { ConfirmMailingSendPopupComponent } from './confirm-mailing-send-popup/confirm-mailing-send-popup.component';
import {TranslateService} from '@ngx-translate/core';
import { MailingMethod } from '@app/views/abonents/models/mailing/mailing-method.model';
import { cloneDeep } from 'lodash';
import { DeleteMailing } from '@app/views/abonents/store/actions/mailing.actions';
import { getDeleteMailingSuccess, mailingStateLoading } from '@app/views/abonents/store/states/mailing.state';
import { MailingDeleteConfirmComponent } from '../mailing-delete-confirm/mailing-delete-confirm.component';
import { DialogWrapperSize } from '@app/shared/ui/dialog-wrapper/dialog-wrapper-size.enum';
import { MailingHelperService } from '@app/views/abonents/services/mailing-helper.service';

@Component({
  selector: 'app-mailing-new',
  templateUrl: './mailing-new.component.html',
  styleUrls: ['./mailing-new.component.scss'], 
})
export class MailingNewComponent implements OnInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;
  @Input() set mailing(mailing: MailingResponse) {
    this._mailing = mailing;
    this.initPage();
  }
  get mailing(): MailingResponse { return this._mailing }

  @Output() submitted: EventEmitter<void> = new EventEmitter();
  @Output() repeated: EventEmitter<{ mailing: MailingResponse }> = new EventEmitter();
  @Output() mailingSuccessDeleted: EventEmitter<void> = new EventEmitter();

  fromNavigationState: boolean;
  mailingMethod = MailingMethod;
  filterInput: UntypedFormControl = new UntypedFormControl();
  form: UntypedFormGroup;
  scheduledDataForm: UntypedFormGroup;
  canDelete: boolean;
  calendarMinDate: Date;
  calendarMaxDate: Date;
  timeMinDate: Date | null;
  currentLocalDate: RdeaDate;
  currentLocalTime: RdeaDate;
  
  addressString: string;
  currentSelect: 'cities' | 'streets' | 'houses' | 'entrances';
  filteredAddresses: { id: number, name?: string, displayedName: string }[];
  addedAddresses: { addressCriteria: AddressCriteriaRequest, displayedName: string }[];
  addressSelected: {
    cities: { loaded: boolean, selectedId: number, selectedName: string },
    streets: { loaded: boolean, selectedId: number, selectedName: string },
    houses: { loaded: boolean, selectedId: number, selectedName: string },
    entrances: { loaded: boolean, selectedId: number, selectedName: string }
  };
  addresses: {
    cities: { id: number, name?: string, displayedName: string }[],
    streets: { id: number, name?: string, displayedName: string }[],
    houses: { id: number, name?: string, displayedName: string }[],
    entrances: { id: number, name?: string, displayedName: string }[]
  };
  
  private readonly mobilePlatformsList = ["google", "apple", "huawei"];
  private _mailing: MailingResponse;
  private onDestroy$: Subject<void> = new Subject();
  private dialogRef: MatDialogRef<EditPopupComponent | MailingDeleteConfirmComponent>;
  
  private lastLocalDate = new Date();
  private lastSavedUtcDate: Date = this.mailingService.getCurrentUtcDate();
  private selectedUtcDate: Date;
  
  get methodFieldValue() { return this.form.get('method').value}
  private get mobilePlatformsField() { return this.form.get('mobilePlatforms') }

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private store: Store<State>,
    private topBar: TopBarService,
    private snackbar: SnackbarService,
    private loaderService: LoaderService,
    private mailingService: MailingHelperService,
    private resolutionService: ResolutionService,
    private translate: TranslateService,
    private addressFormatter: AddressFormatter, 
  ) {
    this.prepareRouteExtras();
  }

  ngOnInit(): void {
    const currentYear = this.lastSavedUtcDate.getFullYear();
    const nextYearDate = cloneDeep(this.lastSavedUtcDate).setFullYear(currentYear + 1);
    this.calendarMaxDate = new Date(nextYearDate);
    this.calendarMinDate = cloneDeep(this.lastSavedUtcDate);

    this.currentLocalDate = this.getCurrentLocalDate(this.lastLocalDate, this.lastSavedUtcDate, this.lastSavedUtcDate);
    this.currentLocalTime = cloneDeep(this.currentLocalDate);

    this.initStore();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.store.dispatch(new ClearAddress());
    this.store.dispatch(new SendMailingClear());
  }

  onSubmit() {
    if (!this.isFormDataValid()) {
      return;
    }

    const selectedPlatforms = [];
    this.mobilePlatformsList.forEach(platform => {
      if (this.mobilePlatformsField?.get(platform)?.value) {
        selectedPlatforms.push(platform);
      }
    });

    const data: EditPopupComponentData = {
      title: this.translate.instant('mailing.page.new.confirm.title'),
      providedData: {},
      component: ConfirmMailingSendPopupComponent,
      componentName: 'ConfirmMailingSend',
      submit: (isConfirmed: string) => {
        if (isConfirmed) {
          const addressCriteria: AddressCriteriaRequest[] = this.addedAddresses.map(address => address.addressCriteria);
          const mailing: MailingRequest = {
            message: this.form.get('message').value,
            deliveryMethod: 'push',
            addressCriteria,
            sendToDelegates: true,
            providers: selectedPlatforms
          };

          if (this.mailingMethod.SCHEDULED === this.methodFieldValue) {
            const utcStartTime = this.mailingService.getUtcTimestamp(this.selectedUtcDate);
            mailing.startTime = utcStartTime;
          }

          this.store.dispatch(new SendMailing(mailing));
        }
      }
    };

    this.dialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolutionService.isMobile ? '100%' : '500px',
      data
    });
  }

  onSelected(data: { id: number, name?: string, displayedName?: string }) {
    const address = data;
    this.addressSelected[this.currentSelect].selectedId = address.id;
    this.addressSelected[this.currentSelect].selectedName = data.name;

    switch (this.currentSelect) {
      case 'cities':
        this.currentSelect = 'streets';
        break;
      case 'streets':
        this.currentSelect = 'houses';
        break;
      case 'houses':
        this.currentSelect = 'entrances';
        break;
      case 'entrances':
        this.currentSelect = 'entrances';
        break;
      default:
        this.currentSelect = 'cities';
    }
    this.addressString += data.name;
  }

  onRepeatMailing() {
    if (this.fromNavigationState) {
      const newMailing: MailingResponse = this.mailingService.initEmptyMailingResponse();
      newMailing.addressCriteria = this.mailing.addressCriteria;
      newMailing.message = this.mailing.message;
      this.mailing = newMailing;
      this.initPage();
    } else {
      this.repeated.emit({ mailing: this.mailing });
    }
  }

  onDeleteMailing() {
    const data: EditPopupComponentData = {
      title: this.translate.instant('mailing.page.new.confirm.delete_title'),
      providedData: {
        width: this.resolutionService.isMobile ? '100%' : DialogWrapperSize.SM,
        isTitleLeft: true
      },
      component: MailingDeleteConfirmComponent,
      componentName: 'MailingDeleteConfirm',
      submit: (isConfirmed: boolean) => {
        if (isConfirmed) {
          this.store.dispatch(new DeleteMailing(this.mailing.id));
        }
      }
    };

    this.dialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  onCanDelete(value: boolean): void {
    this.canDelete = value;
  }

  displayFn(data: { id: number, name?: string, displayedName?: string }): string {
    return data ? data.displayedName : '';
  }

  onAddAddress() {
    this.addedAddresses.push({
      addressCriteria: {
        cityId: this.addressSelected.cities.selectedId,
        streetId: this.addressSelected.streets.selectedId,
        houseId: this.addressSelected.houses.selectedId,
        entranceId: this.addressSelected.entrances.selectedId,
      },
      displayedName: this.addressString
    });
    this.currentSelect = 'cities';
    this.filterInput.setValue('');
    this.addressSelected.streets = { loaded: false, selectedId: null, selectedName: null };
    this.addressSelected.houses = { loaded: false, selectedId: null, selectedName: null };
    this.addressSelected.entrances = { loaded: false, selectedId: null, selectedName: null };
  }

  onRemoveAddress(idx: number) {
    this.addedAddresses.splice(idx, 1);
  }

  isMobile(): boolean {
    return this.resolutionService.isMobile;
  }

  private prepareRouteExtras() {
    const currentNavigation: Navigation = this.router.getCurrentNavigation();
    this.fromNavigationState = !!currentNavigation;

    this.mailing = !currentNavigation?.extras?.state ?
      this.mailingService.initEmptyMailingResponse() : currentNavigation?.extras?.state as MailingResponse;
  }

  private initPage() {
    if (!this.mailing) {
      return;
    }

    this.initVariables();
    this.prepareAddressCriteria();
    this.updateTitles();
    
    if (!this.mailing.id) {
      this.initForm();
      this.initScheduledForm();
      this.initScheduledFormListener();
      this.initCreateBlock();
    }
  }

  private initVariables() {
    this.addressString = '';
    this.currentSelect = 'cities';
    this.filteredAddresses = [];
    this.addresses = { cities: [], streets: [], houses: [], entrances: [] };

    this.addressSelected = {
      cities: { loaded: false, selectedId: null, selectedName: null },
      streets: { loaded: false, selectedId: null, selectedName: null },
      houses: { loaded: false, selectedId: null, selectedName: null },
      entrances: { loaded: false, selectedId: null, selectedName: null }
    };
  }

  private prepareAddressCriteria() {
    if (!this.mailing?.addressCriteria) {
      this.addedAddresses = [];
      return;
    }

    this.addedAddresses = this.mailing.addressCriteria.map(address => {
      const addressCriteria: AddressCriteriaRequest = {
        cityId: address?.city?.id,
        streetId: address?.street?.id,
        houseId: address?.house?.id,
        entranceId: address?.entrance?.id
      };

      const cityStr: string = this.addressFormatter.formatCityResponse(address.city);
      const streetStr: string = this.addressFormatter.formatStreetResponse(address.street);
      const houseStr: string = this.addressFormatter.formatHouseResponse(address.house);
      const entranceStr: string = this.addressFormatter.formatEntranceResponse(address.entrance);
      const displayedName = cityStr + streetStr + houseStr + entranceStr;

      return { addressCriteria, displayedName };
    });
  }

  private initForm() {
    this.form = new UntypedFormGroup({
      message: new UntypedFormControl(null, Validators.required), 
      method: new UntypedFormControl(MailingMethod.STANDART, Validators.required), 
      mobilePlatforms: new UntypedFormGroup({
        apple: new UntypedFormControl(true),
        google: new UntypedFormControl(true),
        huawei: new UntypedFormControl(true),
      })
    });

    if (this.mailing.message) {
      this.form.get('message').setValue(this.mailing.message);
    }

    this.preperedMailingMethod(this.mailing);
    this.preperedMobilePlatforms(this.mailing);
  }

  private preperedMobilePlatforms(mailing: MailingResponse | null): void {
    if (!mailing.providers) {
      return;
    }

    this.mobilePlatformsList.forEach(platform => {
      if (mailing.providers.includes(platform)) {
        this.mobilePlatformsField.get(platform).patchValue(true);
        return;
      }

      this.mobilePlatformsField?.get(platform)?.setValue(false);
    })
  }

  private preperedMailingMethod(mailing: MailingResponse | null): void {
    if (!mailing.createdAt) {
      return;
    }

    const method = this.mailingService.getMailingMethod(mailing);
    this.form?.get('method').setValue(method);
  }

  private initScheduledForm(): void {
    this.lastSavedUtcDate = this.mailingService.getCurrentUtcDate();
    this.lastLocalDate = new Date();
    this.currentLocalDate = this.getCurrentLocalDate(this.lastLocalDate, this.lastSavedUtcDate, this.lastSavedUtcDate);
    this.currentLocalTime = cloneDeep(this.currentLocalDate);

    this.scheduledDataForm = new UntypedFormGroup({
      date: new UntypedFormControl(this.lastSavedUtcDate, Validators.required), 
      time: new UntypedFormControl(this.lastSavedUtcDate, Validators.required)
    })
  }

  private initScheduledFormListener(): void {
    this.scheduledDataForm.valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(values => {
        const {date, time } = values;

        if (date && time) {
          this.selectedUtcDate = this.mailingService.combineDateAndTime(date, time);
        }

        if (date && !time) {
          this.selectedUtcDate = this.mailingService.combineDateAndTime(date, this.currentLocalTime);
        }

        if (time && !date) {
          this.selectedUtcDate = this.mailingService.combineDateAndTime(this.currentLocalDate, time);
        }
        
        const updatedLocalDate = this.getCurrentLocalDate(this.lastLocalDate, this.selectedUtcDate, this.lastSavedUtcDate);
        this.lastSavedUtcDate = this.selectedUtcDate;
        this.lastLocalDate = cloneDeep(updatedLocalDate);

        this.currentLocalDate = date? updatedLocalDate : null;
        this.currentLocalTime = time ? cloneDeep(updatedLocalDate) : null;
      })
  }

  private initCreateBlock() {
    this.initLoading();
    this.initAddressInputListeners();
  }

  private initAddressInputListeners() {
    this.filterInput.valueChanges.pipe(startWith(''), takeUntil(this.onDestroy$))
      .subscribe(value => this.filteredAddresses = this._filter(value));

    this.filterInput.valueChanges.pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((value: string | { id: number, name?: string }) => {
        if (typeof value === 'string') {
          this.prepareAddressInputString(value);
        }
        this.loadAddressElement();
      });
  }

  private prepareAddressInputString(value: string) {
    const citySelectedName: string = this.addressSelected.cities.selectedName;
    if (citySelectedName) {
      const cityMatch = value.indexOf(citySelectedName);
      if (cityMatch === -1) {
        this.addressSelected.cities = { loaded: true, selectedId: null, selectedName: null };
        this.addressSelected.streets = { loaded: false, selectedId: null, selectedName: null };
        this.addressSelected.houses = { loaded: false, selectedId: null, selectedName: null };
        this.addressSelected.entrances = { loaded: false, selectedId: null, selectedName: null };
        this.addresses.streets = [];
        this.addresses.houses = [];
        this.addresses.entrances = [];
        this.addressString = '';
        this.currentSelect = 'cities';
      }
    }

    const streetSelectedName: string = this.addressSelected.streets.selectedName;
    if (streetSelectedName) {
      const streetMatch = value.indexOf(streetSelectedName);
      if (streetMatch === -1) {
        this.addressSelected.streets = { loaded: true, selectedId: null, selectedName: null };
        this.addressSelected.houses = { loaded: false, selectedId: null, selectedName: null };
        this.addressSelected.entrances = { loaded: false, selectedId: null, selectedName: null };
        this.addresses.houses = [];
        this.addresses.entrances = [];
        this.addressString = this.addressString.substr(0, citySelectedName.length);
        this.currentSelect = 'streets';
      }
    }

    const houseSelectedName: string = this.addressSelected.houses.selectedName;
    if (houseSelectedName) {
      const houseMatch = value.indexOf(houseSelectedName);
      if (houseMatch === -1) {
        this.addressSelected.houses = { loaded: true, selectedId: null, selectedName: null };
        this.addressSelected.entrances = { loaded: false, selectedId: null, selectedName: null };
        this.addresses.entrances = [];
        this.addressString = this.addressString.substr(0, citySelectedName.length + streetSelectedName.length);
        this.currentSelect = 'houses';
      }
    }

    const entranceSelectedName: string = this.addressSelected.entrances.selectedName;
    const entraneNumber = value.indexOf(entranceSelectedName);
    if (entranceSelectedName && entraneNumber === -1) {
      this.addressSelected.entrances = { loaded: true, selectedId: null, selectedName: null };
      this.addressString = this.addressString.substr(0, citySelectedName.length + streetSelectedName.length + houseSelectedName.length);
      this.currentSelect = 'entrances';
    }
  }

  private loadAddressElement() {
    if (!this.addressSelected.cities.loaded) {
      this.store.dispatch(new GetCitiesList());
      return;
    }

    if (!this.addressSelected.streets.loaded && this.addressSelected.cities.selectedId) {
      this.store.dispatch(new GetStreetsList(this.addressSelected.cities.selectedId));
      return;
    }

    if (!this.addressSelected.houses.loaded && this.addressSelected.streets.selectedId) {
      this.store.dispatch(new GetHousesList(
        this.addressSelected.cities.selectedId,
        this.addressSelected.streets.selectedId)
      );
      return;
    }

    if (!this.addressSelected.entrances.loaded && this.addressSelected.houses.selectedId) {
      this.store.dispatch(new GetEntrancesList(
        this.addressSelected.cities.selectedId,
        this.addressSelected.streets.selectedId,
        this.addressSelected.houses.selectedId)
      );
      return;
    }
  }

  private initStore() {
    this.initStoreListeners();
  }

  private initStoreListeners() {
    this.store.pipe(select(getCitiesStateSuccess), takeUntil(this.onDestroy$))
      .subscribe(data => {
        if (data?.length > 0) {
          this.addressSelected.cities.loaded = true;

          this.addresses.cities = data.map(city => {
            const cityStr: string = this.addressFormatter.formatCityResponse(city);
            return { id: city.id, name: cityStr, displayedName: cityStr };
          });

          this.filteredAddresses = this._filter(this.filterInput.value);
          this.inputAutoComplete.openPanel();
        }
      });

    this.store.pipe(select(getStreetsStateSuccess), takeUntil(this.onDestroy$))
      .subscribe(data => {
        if (data?.length > 0) {
          this.addressSelected.streets.loaded = true;

          this.addresses.streets = data.map(street => {
            const streetStr: string = this.addressFormatter.formatStreetResponse(street);
            return { id: street.id, name: streetStr, displayedName: this.addressString + streetStr };
          });

          this.filteredAddresses = this._filter('');
          this.inputAutoComplete.openPanel();
        }
      });

    this.store.pipe(select(getHousesStateSuccess), takeUntil(this.onDestroy$))
      .subscribe(data => {
        if (data?.length > 0) {
          this.addressSelected.houses.loaded = true;

          this.addresses.houses = data.map(house => {
            const houseStr: string = this.addressFormatter.formatHouseResponse(house);
            return { id: house.id, name: houseStr, displayedName: this.addressString + houseStr };
          });

          this.filteredAddresses = this._filter('');
          this.inputAutoComplete.openPanel();
        }
      });

    this.store.pipe(select(getEntrancesStateSuccess), takeUntil(this.onDestroy$))
      .subscribe(data => {
        if (data?.length > 0) {
          this.addressSelected.entrances.loaded = true;

          this.addresses.entrances = data.map(entrance => {
            const entranceStr: string = this.addressFormatter.formatEntranceResponse(entrance);
            return { id: entrance.id, name: entranceStr, displayedName: this.addressString + entranceStr };
          });

          this.filteredAddresses = this._filter('');
          this.inputAutoComplete.openPanel();
        }
      });

    this.store.pipe(select(sendMailingStateSuccess), takeUntil(this.onDestroy$))
      .subscribe(data => {
        if (data) {
          if (this.dialogRef) { 
            this.dialogRef.close();
            this.dialogRef = null;
          }

          this.snackbar.showMessage(this.translate.instant('mailing.page.new.message.send.success'), 'success', true);
          if (this.fromNavigationState) {
            this.router.navigate(['/mailing/all']);
          } else {
            this.submitted.emit();
          }
        }
      });

    this.store.pipe(select(mailingStateFailure), takeUntil(this.onDestroy$))
      .subscribe(error => {
        if (error) {
          this.snackbar.showMessage(
            this.translate.instant('mailing.page.new.message.send.failed', {
              text: parseError(error)
            })
          );
        }
      });

    this.store.select(getDeleteMailingSuccess).pipe(takeUntil(this.onDestroy$))
      .subscribe(value => {
        if (value?.mailingDeleted) {
          this.mailingSuccessDeleted.emit();

          if (this.dialogRef) { 
            this.dialogRef.close();
            this.dialogRef = null;
          }
        }
      })
  }

  private initLoading() {
    this.store.select(mailingStateLoading)
      .subscribe((state: boolean) => this.loaderService.loaderState = { state });
  }

  private _filter(value: string): { id: number, name?: string, displayedName: string }[] {
    if (!value || typeof value !== 'string') {
      return this.addresses[this.currentSelect];
    }

    for (const addressItem of ['cities', 'streets', 'houses', 'entrances']) {
      if (this.addressSelected[addressItem].selectedId) {
        value = value.replace(this.addressSelected[addressItem].selectedName, '');
      }
    }

    value = value.toLowerCase().replace(/([,.])/g, '');

    return this.addresses[this.currentSelect]
      .filter(address => address.name.toLowerCase()
        .replace(/([,.])/g, '')
        .includes(value)
      );
  }

  private updateTitles() {
    this.topBar.title = this.mailing.id
      ? this.translate.instant('mailing.page.new.title.update')
      : this.translate.instant('mailing.page.new.title.create')
    ;
  }

  private getCurrentLocalDate(localDate: Date, currentUtcDate: Date, lastUtcDate: Date): RdeaDate {
    return this.mailingService.shiftLocalDateByUtcDifference(localDate, currentUtcDate, lastUtcDate);
  }

  private isFormDataValid(): boolean {
    if (!this.form.valid || this.addedAddresses.length < 1) {
      this.snackbar.showMessage(this.translate.instant('mailing.page.new.message.invalid_data'), 'info');
      return false;
    }

    if (!Object.values(this.mobilePlatformsField.value).includes(true)) {
      this.snackbar.showMessage(this.translate.instant('mailing.page.new.message.invalid_platforms'), 'info');
      return false;
    }

    if (this.mailingMethod.SCHEDULED === this.methodFieldValue) {
      const isCurrentDateGreater = this.mailingService.isCurrentDateGreater(this.selectedUtcDate);

      if (isCurrentDateGreater) {
        this.snackbar.showMessage(this.translate.instant('mailing.page.new.message.invalid_time'), 'info', true);
        return false;
      }
    }

    return true;
  }
}
