<div class="progress-bar__wrapper">
    <ng-container *ngIf="value != null else loadingBlock">
        <p-progressBar 
        class="progress-bar-h4 hide-progress-bar-label" 
        [value]="value"
        [ngClass]="colorClass"
    />
    </ng-container>
    <ng-template #loadingBlock>
        <p-progressBar 
        class="progress-bar-h4 hide-progress-bar-label blue-animation grey-progress-bar" 
        [ngClass]="colorClass"
        mode="indeterminate"
    />
    </ng-template>
</div>
