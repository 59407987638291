<div class="mailing-status mailing-status-wrapper">
  <div class="mailing-status__progress-block">
    <div class="mailing-status__progress-text">
      <div class="mailing-status__chip-block">
        <p-chip *ngIf="isDataReady" [ngClass]="chipColorClass" [label]="mailingStatusTranslation" />
        <p-skeleton class="mailing-status-skeleton" *ngIf="!isDataReady" width="89px" height="25.992px"></p-skeleton>

        <button
          mat-raised-button
          color="primary"
          (click)="dispatchGetBatches(mailingId)"
          [disabled]="mailingStatus === status.COMPLETED"
          class="mailing-status__refresh-button"
          matTooltip="{{ 'mailing.page.new.field.refresh_status' | translate }}"
        >
          <mat-icon>refresh</mat-icon>
        </button>
      </div>

      <div class="mailing-status__percentage-text">
        {{ 'mailing.page.new.field.processed_messages' | translate }}: 
        <span *ngIf="isDataReady">{{processedPercentage}}</span>%
      </div>
    </div>

    <app-progress-bar [value]="processedPercentage" [mailingStatus]="mailingStatus"></app-progress-bar>
  </div>
  

  <div class="mailing-status__details-block">
    <div class="mailing-status__paragraph details-text">
      <div class="mailing-status__text-title">
        <p>{{ 'mailing.page.new.field.processed_messages' | translate }}:</p>
        <p>{{ 'mailing.page.new.field.success_processed_messages' | translate }}:</p>
        <p>{{ 'mailing.page.new.field.failed_processed_messages' | translate }}:</p>
      </div>

        <div class="mailing-status__text" *ngIf="isDataReady">
          <p>
            <span>{{totalProcessedMessages | separateNumber}}</span>
            <span *ngIf="totalProcessedMessages != null">
              <span> {{ 'mailing.page.new.field.from' | translate }} </span>
              <span>{{recipientsCount | separateNumber}}</span>
            </span>
          </p>

          <p>{{successMessages | separateNumber}}</p>
          <p class="mailing-status__failed-messages" *ngIf="failedMessages != null">
            <span class="failed-messages-count">{{failedMessages | separateNumber}} </span> 
            <i 
              class="pi pi-info-circle info-icon" 
              pTooltip="<strong>{{ 'mailing.page.new.field.mailing_failed_reason' | translate }}</strong><br>
                {{ 'mailing.page.new.field.mailing_failed_reason_details' | translate }}"
              [tooltipOptions]="{escape: false}"
              [tooltipStyleClass]="tooltipStyleClass"
            ></i>
          </p>
        </div>

        <div *ngIf="!isDataReady">
          <div class="mailing-status__skeleton">
            <p-skeleton width="100%" height="18px"></p-skeleton>
          </div>
          <div class="mailing-status__skeleton">
            <p-skeleton width="100%" height="18px"></p-skeleton>
          </div>
          <div>
            <p-skeleton width="100%" height="18px"></p-skeleton>
          </div>
        </div>
    </div>

    <div class="mailing-status__paragraph general-text">
      <div class="mailing-status__text-title">
        <p>{{ 'mailing.page.new.field.user' | translate }}:</p>
        <p>{{ 'mailing.page.new.field.mailing_start' | translate }}:</p>
        <p>{{ 'mailing.page.new.field.mailing_end' | translate }}:</p>
      </div>
      <div class="mailing-status__text" *ngIf="isDataReady">
        <p>{{mailingSender}}</p>
        <p>{{mailingStartDate | date: 'dd.MM.yyyy • HH:mm:ss'}}
          <span *ngIf="mailingStatus !== status.COMPLETED"> ~</span>
        </p>
        <p class="last-text">
          {{mailingEndDate | date: 'dd.MM.yyyy • HH:mm:ss'}}
          <span *ngIf="mailingStatus !== status.COMPLETED"> ~</span>
        </p>
      </div>

      <div *ngIf="!isDataReady">
        <div class="mailing-status__skeleton">
          <p-skeleton width="100%" height="18px"></p-skeleton>
        </div>
        <div class="mailing-status__skeleton">
          <p-skeleton width="100%" height="18px"></p-skeleton>
        </div>
        <div>
          <p-skeleton width="100%" height="18px"></p-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>

