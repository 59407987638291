import { Inject, Injectable } from "@angular/core";
import { PeerConnectionService } from "../peer-connection/peer-connection.service";
import { IVideoConfig, IVideoConfigHls, IVideoConfigWebRTC, VideoShotData } from "../models/video.models";
import { SignalService } from "../signal-serivice/signal.service";
import { HttpApiService } from "../message-service/http-api.service";
import { HlsConnectionService } from "../hls-service/hls-connection.service";
import { MediaService } from "../media.service/media.service";
import { RdeaHls, VideoPlayerFragmentShortDate } from "@app/shared/components/video-player";
import { StoreService } from "./video-store/store.service";
import { TimelineData } from "../models/timeline.model";
import { PlayerStateEnum } from "../models/state.model";
import { Option } from "../models/select.model";
import { Observable } from "rxjs";
import { ErrorData } from "hls.js";
import { RdvaPlaylistItem } from "@app/shared/entities/integrations";
import { HlsManifestItems } from "../models/hls.model";
import { HlsHelper } from "../helpers/hls-helper";

@Injectable({ providedIn: 'root' })
export class RdVideoService {
  mediaStreamList$ = this.mediaService.mediaInfoList$;

  constructor(
    private signalService: SignalService,
    private httpApiService: HttpApiService,
    private mediaService: MediaService,
    private peerConnectionService: PeerConnectionService,
    private hlsConnectionService: HlsConnectionService,
    private storeService: StoreService
  ) { }

  addReceiverWebRTC(config: IVideoConfigWebRTC): void {
    this.signalService.init();
    this.peerConnectionService.createPeerConnection(config.id);
  }

  addReceiverHls(config: IVideoConfigHls): void {
    this.hlsConnectionService.init(config);
  }

  updateCurrentPlaylist(value: RdvaPlaylistItem): void {
    this.storeService.updateCurrentPlaylist(value);
  }

  updateSelectedPlayerTime(time: number): void {
    this.storeService.updatePlayerCurrentTime(time);
  }

  updateSelectPlaylistPlayer(item: Option): void {
    this.storeService.updateSelectedPlaylistPlayer(item);
  }

  updatePlayerTimelineData(timelineData: TimelineData): void {
    this.storeService.updateTimelineData(timelineData);
  }

  updateCurrentEvent(option: Option<VideoPlayerFragmentShortDate>): void {
    this.storeService.updateCurrentEvent(option);
    this.storeService.fireNewPlayerCurrentTime(option.value.startPlayerTime);
  }

  onHlsConnectionErrorType$(): Observable<ErrorData>  {
    return this.storeService.onHlsConnectionErrorType$()
  }

  onPlayListItems$(): Observable<RdvaPlaylistItem[]>  {
    return this.storeService.selectPlayListItems$()
  }

  onCurrentPlaylist$(): Observable<RdvaPlaylistItem>  {
    return this.storeService.selectCurrentPlaylist$()
  }

  onTimelineData$(): Observable<TimelineData>  {
    return this.storeService.selectTimelineData$()
  }

  updateEventOptions(value: Option<VideoPlayerFragmentShortDate>[]): void  {
    this.storeService.updateEventOptions(value)
  }

  createOption<T>(value: T, label: string): Option<T> {
    return {
      value,
      label
    }
  }

  getPlaylistHlsManifestItems(): HlsManifestItems {
    return this.storeService.getPlaylistHlsManifestItems();
  }
  
  destroyWebRTCStream(id: number): void {
    this.signalService.destroy();
    this.peerConnectionService.destroyPeerConnection(id);
  }

  destroyHlsStream(id: number): void {
    this.hlsConnectionService.destroyHls(id);
  }

  destroy(): void {
    this.signalService.destroy();
    this.peerConnectionService.destroy();
    this.hlsConnectionService.destroy();
    this.mediaService.destroy();
  }
}
