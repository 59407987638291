import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CoreGetUnresolvedIssuesCount} from '@app/core';
import {BreadcrumbService, TopBarService} from '@app/core/services';
import {SnackbarService} from '@app/shared/components';
import {LoaderService} from '@app/shared/entities/common';
import {
  AbonentService,
  AbonentStat,
  AccountApiService,
  DelegationsApiService,
  GetDelegationResponse,
  MonitoringService,
  ServiceApiService,
  ServiceInfoResponse,
} from '@app/shared/entities/rd';
import {Constants, parseError} from '@app/shared/helpers';
import {Abonent, ServicesTypes, SignUpStatus} from '@app/shared/models';
import {RequestsService} from '@app/shared/services';
import {State} from '@app/store/reducers';
import {AccountServiceData, Chat, ChatContent, VoipTokenInfo} from '@app/views/abonents/models';
import {ChatService} from '@app/views/abonents/services';
import {abonentPageInfoState} from '@app/views/abonents/store/states';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {forkJoin, from, Observable, of} from 'rxjs';
import {bufferCount, catchError, filter, finalize, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
  ActionTypes,
  BlockAbonentPageAccount,
  BlockAbonentPageAccountFailure,
  BlockAbonentPageAccountSuccess,
  BlockAbonentPageService,
  BlockAbonentPageServiceFailure,
  BlockAbonentPageServiceSuccess,
  ChangeDateDiff,
  ChangeVoipToken,
  DeleteAbonentPageAccount,
  DeleteAbonentPageAccountFailure,
  DeleteAbonentPageAccountSuccess,
  DeleteAbonentPageDelegation,
  DeleteAbonentPageDelegationFailure,
  DeleteAbonentPageDelegationSuccess,
  DisconnectAbonentPageService,
  DisconnectAbonentPageServiceFailure,
  DisconnectAbonentPageServiceSuccess,
  GetAbonentPageAccounts,
  GetAbonentPageAccountServices,
  GetAbonentPageAccountServicesFailure,
  GetAbonentPageAccountServicesSuccess,
  GetAbonentPageAccountsFailure,
  GetAbonentPageAccountsSuccess,
  GetAbonentPageInfo,
  GetAbonentPageInfoFailure,
  GetAbonentPageInfoSuccess,
  GetAbonentPageMessages,
  GetAbonentPageMessagesFailure,
  GetAbonentPageMessagesSuccess,
  GetAbonentPageOwnerDelegations,
  GetAbonentPageOwnerDelegationsFailure,
  GetAbonentPageOwnerDelegationsSuccess,
  GetAbonentPageSharedDelegations,
  GetAbonentPageSharedDelegationsFailure,
  GetAbonentPageSharedDelegationsSuccess,
  GetAbonentPageSignUps,
  GetAbonentPageSignUpsFailure,
  GetAbonentPageSignUpsSuccess,
  GetAbonentPageStat,
  GetAbonentPageStatFailure,
  GetAbonentPageStatSuccess,
  GetAbonentPageVoipTokens,
  GetAbonentPageVoipTokensFailure,
  GetAbonentPageVoipTokensSuccess,
  LoadAbonentPageMessages,
  LoadAbonentPageMessagesFailure,
  LoadAbonentPageMessagesSuccess,
  SendAbonentPageMessage,
  SendAbonentPageMessageFailure,
  SendAbonentPageMessageSuccess,
  UnblockAbonentPageAccount,
  UnblockAbonentPageAccountFailure,
  UnblockAbonentPageAccountSuccess,
  UnblockAbonentPageService,
  UnblockAbonentPageServiceFailure,
  UnblockAbonentPageServiceSuccess,
  UpdateAbonentPageAccount,
  UpdateAbonentPageAccountFailure,
  UpdateAbonentPageAccountSuccess,
  UpdateAbonentPageInfo,
  UpdateAbonentPageInfoFailure,
  UpdateAbonentPageInfoSuccess,
  UpdateAbonentPageSignUp,
  UpdateAbonentPageSignUpFailure,
  UpdateAbonentPageSignUpSuccess
} from '../actions/abonent-page.actions';
import {abonentPageSelectedDateDiff, abonentPageSelectedVoipToken} from '../states/abonent-page.state';
import {TranslateService} from '@ngx-translate/core';
import { SignUpType } from '@app/shared/models/sign-ups/sign-ups-types.enum';

@Injectable()
export class AbonentPageEffects {
  constructor(
    private actions$: Actions,
    private chatService: ChatService,
    private abonentService: AbonentService,
    private accountApiService: AccountApiService,
    private requestsService: RequestsService,
    private monitoringService: MonitoringService,
    private delegationsApiService: DelegationsApiService,
    private snackbar: SnackbarService,
    private dialog: MatDialog,
    private breadcrumb: BreadcrumbService,
    private topBar: TopBarService,
    private store: Store<State>,
    private loader: LoaderService,
    private translate: TranslateService, 
    private serviceApi: ServiceApiService
  ) {
  }

  GetAbonentPageInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAbonentPageInfo>(ActionTypes.GetAbonentPageInfo),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.abonentService.getAbonentInfo(action.abonentId)
          .pipe(
            map((response: Abonent) => {
              this.updateTitles(response.phone.toString());
              this.loader.loaderState = {state: false};
              return new GetAbonentPageInfoSuccess(response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.get.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetAbonentPageInfoFailure());
            })
          );
      })
    )
  );

  GetAbonentPageVoipTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAbonentPageVoipTokens>(ActionTypes.GetAbonentPageVoipTokens),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.abonentService.getVoipTokensInfoList(action.abonentId)
          .pipe(
            map((response: VoipTokenInfo[]) => {
              this.loader.loaderState = {state: false};

              if (response.length) {
                this.store.dispatch(new ChangeVoipToken(response[0], action.abonentId));
              }

              return new GetAbonentPageVoipTokensSuccess(response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.get_voip_tokens.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetAbonentPageVoipTokensFailure());
            })
          );
      })
    )
  );

  ChangeVoipToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ChangeVoipToken>(ActionTypes.ChangeVoipToken),
      withLatestFrom(
        this.store.select(abonentPageSelectedDateDiff)
      ),
      tap(([action, dateDiff]) => {
        if (!dateDiff) {
          return;
        }

        this.store.dispatch(new GetAbonentPageStat(
          action.abonentId,
          action.voipToken.token,
          dateDiff * Constants.ABONENTS_MINUTE
        ));
      })
    ), {dispatch: false}
  );

  ChangeDateDiff$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ChangeDateDiff>(ActionTypes.ChangeDateDiff),
      withLatestFrom(
        this.store.select(abonentPageSelectedVoipToken),
      ),
      tap(([action, voipToken]) => {
        if (!voipToken) {
          return;
        }

        this.store.dispatch(new GetAbonentPageStat(
          action.abonentId,
          voipToken.token,
          action.dateDiff * Constants.ABONENTS_MINUTE
        ));
      })
    ), {dispatch: false}
  );

  GetAbonentPageStat$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAbonentPageStat>(ActionTypes.GetAbonentPageStat),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.monitoringService.getAbonentStat(action.abonentId, action.voipToken, action.dateStartDiff)
          .pipe(
            map((response: AbonentStat) => {
              this.loader.loaderState = {state: false};
              return new GetAbonentPageStatSuccess(response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.get_logs.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetAbonentPageStatFailure());
            })
          );
      })
    )
  );

  UpdateAbonentPageInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAbonentPageInfo>(ActionTypes.UpdateAbonentPageInfo),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.abonentService.updateAbonent(action.abonentId, action.request)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.update.success'), 'success'
              );
              this.store.dispatch(new CoreGetUnresolvedIssuesCount());
              this.loader.loaderState = {state: false};
              return new UpdateAbonentPageInfoSuccess(action.request);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.update.failed', {
                  text: parseError(error)
                })
              );
              this.loader.loaderState = {state: false};
              return of(new UpdateAbonentPageInfoFailure());
            })
          );
      })
    )
  );

  GetAbonentPageMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAbonentPageMessages>(ActionTypes.GetAbonentPageMessages),
      switchMap(action =>
        this.chatService.getChat(action.id, action.channel, action.page, action.size)
          .pipe(
            map((response: Chat) =>
              new GetAbonentPageMessagesSuccess(response)
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.get_messages.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetAbonentPageMessagesFailure());
            })
          )
      )
    )
  );

  LoadAbonentPageMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadAbonentPageMessages>(ActionTypes.LoadAbonentPageMessages),
      switchMap(action =>
        this.chatService.getChat(action.id, action.channel, action.page, action.size)
          .pipe(
            map((response: Chat) => new LoadAbonentPageMessagesSuccess(response)),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.get_messages.failed', {
                  text: parseError(error)
                })
              );
              return of(new LoadAbonentPageMessagesFailure());
            })
          )
      )
    )
  );

  SendAbonentPageMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SendAbonentPageMessage>(ActionTypes.SendAbonentPageMessage),
      switchMap(action =>
        this.chatService.sendMessage(action.abonentId, action.message)
          .pipe(
            map((response: ChatContent) => {
              if (response.success === false) {
                this.snackbar.showMessage(
                  this.translate.instant('abonent.page.message.send_message.wait'), 'info'
                );
              }

              return new SendAbonentPageMessageSuccess(response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.send_message.failed', {
                  text: parseError(error)
                })
              );
              return of(new SendAbonentPageMessageFailure());
            })
          )
      )
    )
  );

  GetAbonentPageOwnerDelegations$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAbonentPageOwnerDelegations>(ActionTypes.GetAbonentPageOwnerDelegations),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.delegationsApiService.getDelegations(action.fromAbonentId, null)
          .pipe(
            map((delegations: GetDelegationResponse[]) => {
              this.loader.loaderState = {state: false};
              return new GetAbonentPageOwnerDelegationsSuccess(delegations);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.get_delegations.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetAbonentPageOwnerDelegationsFailure());
            })
          );
      })
    )
  );

  GetAbonentPageSharedDelegations$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAbonentPageSharedDelegations>(ActionTypes.GetAbonentPageSharedDelegations),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.delegationsApiService.getDelegations(null, action.toAbonentId)
          .pipe(
            map((delegations: GetDelegationResponse[]) => {
              this.loader.loaderState = {state: false};
              return new GetAbonentPageSharedDelegationsSuccess(delegations);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.get_shared_delegations.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetAbonentPageSharedDelegationsFailure());
            })
          );
      })
    )
  );

  DeleteAbonentPageDelegation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteAbonentPageDelegation>(ActionTypes.DeleteAbonentPageDelegation),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.delegationsApiService.deleteDelegation(action.delegationId)
          .pipe(
            map(() => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.delete_delegation.success'),
                'success'
              );
              this.dialog.closeAll();
              return new DeleteAbonentPageDelegationSuccess(action.delegationId);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.delete_delegation.failed', {
                  text: parseError(error)
                })
              );
              return of(new DeleteAbonentPageDelegationFailure());
            })
          );
      })
    )
  );

  GetAbonentPageSignUps$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAbonentPageSignUps>(ActionTypes.GetAbonentPageSignUps),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.requestsService.getSignUpsV2List(0, 100, {abonentId: action.abonentId})
          .pipe(
            map(response => {
              this.loader.loaderState = {state: false};
              return new GetAbonentPageSignUpsSuccess(response.content);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.get_signups.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetAbonentPageSignUpsFailure());
            })
          );
      })
    )
  );

  GetAbonentPageAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAbonentPageAccounts>(ActionTypes.GetAbonentPageAccounts),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.abonentService.getAbonentAccounts(action.abonentId)
          .pipe(
            map(response => {
              this.loader.loaderState = {state: false};
              return new GetAbonentPageAccountsSuccess(response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.get_accounts.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetAbonentPageAccountsFailure());
            })
          );
      })
    )
  );

  UpdateAbonentPageAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAbonentPageAccount>(ActionTypes.UpdateAbonentPageAccount),
      switchMap(action => {
        this.loader.loaderState = {state: true, type: 'component'};

        return this.accountApiService.updateAccount(action.accountId, action.accountNumber)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.update.success'), 'success'
              );
              this.dialog.closeAll();
              this.loader.loaderState = {state: false};
              return new UpdateAbonentPageAccountSuccess(action.accountId, action.accountNumber);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.update.failed', {
                  text: parseError(error)
                })
              );
              this.loader.loaderState = {state: false};
              return of(new UpdateAbonentPageAccountFailure());
            })
          );
      })
    )
  );

  DeleteAbonentPageAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteAbonentPageAccount>(ActionTypes.DeleteAbonentPageAccount),
      switchMap(action => {
        this.loader.loaderState = {state: true, type: 'component'};

        return this.accountApiService.deleteAccount(action.accountId)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.delete.success'), 'success'
              );
              this.dialog.closeAll();
              this.loader.loaderState = {state: false};
              return new DeleteAbonentPageAccountSuccess(action.accountId);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.delete.failed', {
                  text: parseError(error)
                })
              );
              this.loader.loaderState = {state: false};
              return of(new DeleteAbonentPageAccountFailure());
            })
          );
      })
    )
  );

  BlockAbonentPageAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BlockAbonentPageAccount>(ActionTypes.BlockAbonentPageAccount),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.accountApiService.blockAccount(action.accountId)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.block.success'), 'success'
              );
              this.loader.loaderState = {state: false};
              return new BlockAbonentPageAccountSuccess(action.accountId);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.block.failed', {
                  text: parseError(error)
                })
              );
              this.loader.loaderState = {state: false};
              return of(new BlockAbonentPageAccountFailure());
            })
          );
      })
    )
  );

  UnblockAbonentPageAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnblockAbonentPageAccount>(ActionTypes.UnblockAbonentPageAccount),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.accountApiService.unblockAccount(action.accountId)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.unblock.success'), 'success'
              );
              this.loader.loaderState = {state: false};
              return new UnblockAbonentPageAccountSuccess(action.accountId);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.unblock.failed', {
                  text: parseError(error)
                })
              );
              this.loader.loaderState = {state: false};
              return of(new UnblockAbonentPageAccountFailure());
            })
          );
      })
    )
  );

  GetAbonentPageAccountServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAbonentPageAccountServices>(ActionTypes.GetAbonentPageAccountServices),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.accountApiService.getAccountServices(action.accountId)
          .pipe(
            switchMap(accountServices => {
              const hardwareIntercoms = accountServices.filter(service => service.type === ServicesTypes.HARDWARE_INTERCOM);
              const otherServices = accountServices.filter(service => service.type !== ServicesTypes.HARDWARE_INTERCOM);
              const updatedServices: AccountServiceData[] = [];

              if (hardwareIntercoms.length === 0) {
                return of(otherServices);
              }

              updatedServices.push(...otherServices);

              const setBindedServiceRequests = hardwareIntercoms.map(service => 
                this.serviceApi.getMainServiceByDependentService(service.id)
                .pipe(
                  map(bindedService => {
                    service.bindedSoftwareIntercomeId = bindedService.id;
                    return service;
                  })
                )
              );

              return forkJoin(setBindedServiceRequests)
                .pipe(
                  map(updatedHardwareIntercoms => {
                    updatedServices.push(...updatedHardwareIntercoms);
                    return updatedServices;
                  })
                );
            }),
            map(updatedAccountServices => new GetAbonentPageAccountServicesSuccess(updatedAccountServices)),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.get_services.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetAbonentPageAccountServicesFailure());
            }),
            finalize(() => this.loader.loaderState = {state: false})
          );
      })
    ))
       
  DisconnectAbonentPageService$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DisconnectAbonentPageService>(ActionTypes.DisconnectAbonentPageService),
      switchMap(action => {
        this.loader.loaderState = {state: true, type: 'component'};

        return this.accountApiService.disconnectAccountService(action.accountId, action.serviceId, action.request)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.disconnect_service.success'),
                'success'
              );
              this.dialog.closeAll();
              this.loader.loaderState = {state: false};
              return new DisconnectAbonentPageServiceSuccess(action.serviceId, action.request.flatId);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.disconnect_service.failed', {
                  text: parseError(error)
                })
              );
              this.loader.loaderState = {state: false};
              return of(new DisconnectAbonentPageServiceFailure());
            })
          );
      })
    )
  );

  BlockAbonentPageService$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BlockAbonentPageService>(ActionTypes.BlockAbonentPageService),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.accountApiService.blockAccountService(action.accountId, action.serviceId, action.request)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.block_service.success'),
                'success'
              );
              this.loader.loaderState = {state: false};
              return new BlockAbonentPageServiceSuccess(action.serviceId, action.request.flatId);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.block_service.failed', {
                  text: parseError(error)
                })
              );
              this.loader.loaderState = {state: false};
              return of(new BlockAbonentPageServiceFailure());
            })
          );
      })
    )
  );

  UnblockAbonentPageService$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnblockAbonentPageService>(ActionTypes.UnblockAbonentPageService),
      switchMap(action => {
        this.loader.loaderState = {state: true};

        return this.accountApiService.unblockAccountService(action.accountId, action.serviceId, action.request)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.unblock_service.success'),
                'success'
              );
              this.loader.loaderState = {state: false};
              return new UnblockAbonentPageServiceSuccess(action.serviceId, action.request.flatId);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('abonent.page.message.unblock_service.failed', {
                  text: parseError(error)
                })
              );
              this.loader.loaderState = {state: false};
              return of(new UnblockAbonentPageServiceFailure());
            })
          );
      })
    )
  );

  UpdateAbonentPageSignUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAbonentPageSignUp>(ActionTypes.UpdateAbonentPageSignUp),
      withLatestFrom(this.store.select(abonentPageInfoState)),
      switchMap(([action, abonent]) => {
        this.loader.loaderState = {state: true, type: 'component'};

        return this.requestsService.updateSignUpV2(action.id, action.request)
          .pipe(
            map(() => {
              let message: string;

              switch (action.request.status) {
                case SignUpStatus.PROCESSED:
                  message = this.translate.instant('abonent.page.message.sign_up_status.success.processed');
                  break;
                case SignUpStatus.CONNECTED:
                  this.store.dispatch(new CoreGetUnresolvedIssuesCount());
                  this.store.dispatch(new GetAbonentPageAccounts(abonent.id));

                  message = action.signUpType === SignUpType.DISCONNECTION ? 
                    this.translate.instant('abonent.page.message.sign_up_status.success.disconnected') :
                    this.translate.instant('abonent.page.message.sign_up_status.success.connected');
                  break;
                case SignUpStatus.DELEGATED:
                  this.store.dispatch(new CoreGetUnresolvedIssuesCount());
                  message = this.translate.instant('abonent.page.message.sign_up_status.success.delegated');
                  break;
                case SignUpStatus.REJECTED:
                  this.store.dispatch(new CoreGetUnresolvedIssuesCount());
                  message = this.translate.instant('abonent.page.message.sign_up_status.success.rejected');
                  break;
                default:
                  message = this.translate.instant('abonent.page.message.sign_up_status.success.updated');
                  break;
              }

              this.snackbar.showMessage(message, 'success');
              this.dialog.closeAll();
              this.loader.loaderState = {state: false};
              return new UpdateAbonentPageSignUpSuccess(action.id, action.request);
            }),
            catchError((error: HttpErrorResponse) => {
              let message: string;

              switch (action.request.status) {
                case SignUpStatus.PROCESSED:
                  message = this.translate.instant('abonent.page.message.sign_up_status.failed.processed', {
                    text: parseError(error)
                  });
                  break;
                case SignUpStatus.REJECTED:
                  message = this.translate.instant('abonent.page.message.sign_up_status.failed.rejected', {
                    text: parseError(error)
                  });
                  break;
                default:
                  message = this.translate.instant('abonent.page.message.sign_up_status.failed.default', {
                    text: parseError(error)
                  });
                  break;
              }

              this.snackbar.showMessage(message);
              this.loader.loaderState = {state: false};
              return of(new UpdateAbonentPageSignUpFailure());
            })
          );
      })
    )
  );

  private updateTitles(phone: string) {
    this.breadcrumb.title = this.translate.instant('abonent.page.title') + ' +' + phone;
    this.topBar.title = this.translate.instant('abonent.page.title') + ' +' + phone;
  }
}