import { AddressCriteriaRequest } from '@app/views/abonents/models';

export interface MailingRequest {
  message: string;
  deliveryMethod: 'push' | 'sms' | 'push_or_sms';
  addressCriteria: AddressCriteriaRequest[];
  sendToDelegates?: boolean;
  providers: string[], 
  startTime?: number
}
