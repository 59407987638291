import { Component, Inject} from '@angular/core';
import { PopupBasicComponent } from '@app/shared/templates/popup-basic/popup-basic.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-mailing-delete-confirm',
  templateUrl: './mailing-delete-confirm.component.html',
  styleUrls: ['./mailing-delete-confirm.component.scss']
})
export class MailingDeleteConfirmComponent extends PopupBasicComponent {
  
  constructor(
    @Inject('data') public data: {width: string}, 
    @Inject('subject') private subject: Subject<any>
  ) {
    super()
  }

  public onDelete(): void {
    this.subject.next(true);
  }
}
