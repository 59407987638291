import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { FileResponse } from '@app/shared/api';
import { Subject } from 'rxjs';
import { CompanyApiService, ReportsUtils, UploadPaymentTemplateResponse } from '@app/shared/entities/rd';
import { ResolutionService } from '@app/shared/entities';
import { SnackbarService } from '@app/shared/components';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-payments-upload-index',
  templateUrl: './payments-upload-index.component.html',
  styleUrls: ['./payments-upload-index.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentsUploadIndexComponent implements OnInit, OnDestroy {
  @ViewChild('fileUpload') fileUpload: ElementRef;

  public isUploaded: boolean;
  public isLoadingDownload: boolean;
  public isLoadingUpload: boolean;
  public isLoadingUploadConfirm: boolean;

  public items: UploadPaymentTemplateResponse[];

  private file: File;
  private unSubscribe$: Subject<boolean>;

  readonly tableColumns: string[] = [
    'id', 'phone', 'number', 'address', 'datePeriod', 'createdAt', 'updatedAt', 'status', 'error'
  ];

  constructor(
    private readonly companyService: CompanyApiService,
    private translate: TranslateService,
    public resolution: ResolutionService,
    private snackbar: SnackbarService
  ) {}

  ngOnInit(): void {
    this.unSubscribe$ = new Subject<boolean>();
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next(true);
    this.unSubscribe$.unsubscribe();
  }

  get isLoading(): boolean {
    return this.isLoadingDownload || this.isLoadingUpload || this.isLoadingUploadConfirm;
  }

  onDownload(): void {
    this.isLoadingDownload = true;
    this.companyService.getPaymentsManualTemplate()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res: FileResponse) => {
          this.isLoadingDownload = false;
          this.handleDownloadAction(res);
        }
      );
  }

  onUpload(): void {
    this.fileUpload.nativeElement.value = '';
    this.fileUpload.nativeElement.click();
  }

  onUploadProcess(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (!files.length) {
      return;
    }

    this.file = files[0];

    this.isLoadingUpload = true;

    this.handleUpload(false);
  }

  onUploadConfirm(): void {
    this.isLoadingUploadConfirm = true;
    this.handleUpload(true);
  }

  onCancel(): void {
    this.isUploaded = false;
    this.items = null;
    this.file = null;
    this.fileUpload.nativeElement.value = '';
  }

  private handleDownloadAction(fileResponse: FileResponse) {
    ReportsUtils.downLoadFile(fileResponse.arrayBuffer, decodeURI(fileResponse.fileName));
  }

  private handleUpload(isConfirm: boolean) {
    if (!this.file) {
      return;
    }

    this.companyService.uploadPaymentsManualTemplate(this.file, isConfirm)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res: UploadPaymentTemplateResponse[]) => {
          this.isLoadingUpload = false;
          this.isLoadingUploadConfirm = false;

          this.handleUploadAction(res, isConfirm);
        }
      );
  }

  private handleUploadAction(response: UploadPaymentTemplateResponse[], isConfirm: boolean){
    if (isConfirm) {
      this.onCancel();
      this.snackbar.showMessage(
        this.translate.instant('payments.upload.message.confirm.success'),
        'success'
      );
    } else {
      this.items = response.sort((a: UploadPaymentTemplateResponse, b: UploadPaymentTemplateResponse) => a.id - b.id);
      this.isUploaded = true;
    }
  }
}
