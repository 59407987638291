import { HttpErrorResponse } from '@angular/common/http';
import { MailingBatchesResponse, MailingResponse } from '@app/views/abonents/models';
import { Actions, ActionTypes } from '../actions/mailing.actions';

export interface MailingState {
  mailings: MailingResponse[];
  totalCount: number;
  sendedMailing: MailingResponse;
  error: HttpErrorResponse;
  mailingsLoading: boolean;
  mailingLoading: boolean;
  mailingBatches: MailingBatchesResponse[];
  getMailingBatchesError: HttpErrorResponse,
  deleteMailingSuccess: {mailingDeleted: boolean};
}

export const mailingInitialState: MailingState = {
  mailings: [],
  totalCount: null,
  sendedMailing: null,
  error: null,
  mailingsLoading: false,
  mailingLoading: false, 
  mailingBatches: null, 
  getMailingBatchesError: null,
  deleteMailingSuccess: null,
};

export function mailingReducer(state = mailingInitialState, action: Actions): MailingState {
  switch (action.type) {
    case ActionTypes.GetMailings: {
      return {
        ...state,
        error: null,
        totalCount: null,
        mailingsLoading: true
      };
    }

    case ActionTypes.SendMailing: {
      return {
        ...state,
        error: null,
        mailingLoading: true
      };
    }

    case ActionTypes.GetMailingsSuccess: {
      const newMailingList = action.isForScrollingFeed ? state.mailings.concat(action.payload) : action.payload;

      return {
        ...state,
        mailings: newMailingList,
        totalCount: action.totalCount,
        error: null,
        mailingsLoading: false
      };
    }

    case ActionTypes.SendMailingSuccess: {
      state.mailings.unshift(action.mailing);
      state.mailings.pop();
      state.totalCount++;

      return {
        ...state,
        sendedMailing: action.mailing,
        error: null,
        mailingLoading: false
      };
    }

    case ActionTypes.SendMailingFailure: {
      return {
        ...state,
        error: action.payload,
        mailingLoading: false
      };
    }

    case ActionTypes.GetMailingsFailure: {
      return {
        ...state,
        error: action.payload,
        mailingsLoading: false
      };
    }

    case ActionTypes.SendMailingClear: {
      return {
        ...state,
        sendedMailing: null,
        mailingLoading: null,
        error: null
      };
    }

    case ActionTypes.GetMailingsClear: {
      return {
        ...state,
        mailings: [],
        totalCount: null,
        error: null,
        mailingsLoading: null
      };
    }

    case ActionTypes.GetMailingBatchesSuccess: {
      return {
        ...state, 
        mailingBatches: action.payload
      }
    }

    case ActionTypes.GetMailingBatchesFailure: {
      return {
        ...state, 
        getMailingBatchesError: action.payload
      }
    }

    case ActionTypes.DeleteMailing: {
      return {
        ...state,
        error: null,
        mailingLoading: true
      };
    }

    case ActionTypes.DeleteMailingSuccess: {
      return {
        ...state, 
        deleteMailingSuccess: action.payload, 
        mailingLoading: false
      }
    }

    case ActionTypes.DeleteMailingFailure: {
      return {
        ...state,
        mailingLoading: false
      }
    }

    default: {
      return state;
    }
  }
}
