import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MailingResponse, MailingRequest, MailingBatchesResponse } from '@app/views/abonents/models';
import { ApiService } from '@app/shared/api';
import { PagedResponse } from '@app/shared/models';
import { Observable} from 'rxjs';
import { environment } from 'environments/environment';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class MailingApiService extends ApiService {
  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.abonentsApiUrl, ['v1']);
  }

  getMailings(page?: number, size?: number): Observable<PagedResponse<MailingResponse>> {
    const params: any = {};
    params['size'] = size ?? 20;
    params['page'] = page ?? 0;
    params['sort'] = 'createdAt,desc';

    return this.get('/mass_notifications', 1, { params });
  }

  sendMailing(mailing: MailingRequest): Observable<MailingResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post('/mass_notifications', 1, mailing, { headers });
  }

  getMailingBatches(mailingId: number): Observable<MailingBatchesResponse[]> {
    return this.get(`/mass_notifications/${mailingId}/batches`, 1);
  }

  deleteMailing(mailingId: number): Observable<any> {
    return this.delete(`/mass_notifications/${mailingId}`, 1);
  }
}
