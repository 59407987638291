import { Injectable } from '@angular/core';
import { MailingResponse } from '../models';
import { MailingMethod } from '../models/mailing/mailing-method.model';
import { RdeaDate } from '@app/shared/entities';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MailingHelperService {
  private readonly dateFormat = 'YYYY-MM-DD HH:mm:ss';
  
  getCurrentUtcDate(): Date {
    return new Date(moment.utc(new Date()).format(this.dateFormat));
  }

  initEmptyMailingResponse(): MailingResponse {
    return {
      id: null,
      message: null,
      deliveryMethod: null,
      user: null,
      addressCriteria: null,
      recipientsCount: null,
      createdAt: null,
      startTime: null,
      providers: null
    };
  }

  getMailingMethod(mailing: MailingResponse): MailingMethod {
    if (!mailing.startTime) {
      return MailingMethod.STANDART;
    }

    const threeMinutes = 60000 * 3;
    if (mailing.createdAt + threeMinutes < mailing.startTime) {
      return MailingMethod.SCHEDULED;
    }

    return MailingMethod.STANDART;
  }

  getUtcTimestamp(utcDate: Date): number {
    return new Date(new Date(Date.UTC(
      utcDate.getFullYear(),
      utcDate.getMonth(),
      utcDate.getDate(),
      utcDate.getHours(),
      utcDate.getMinutes(),
      utcDate.getSeconds()
    ))).getTime();
  }

  combineDateAndTime(date: Date, time: Date): RdeaDate {
    return new RdeaDate(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds()
    );
  }

  isCurrentDateGreater(selectedDate: Date): boolean {
    const currentUtcTime: Date = this.getCurrentUtcDate();

    if (this.isDatesEquel(selectedDate, currentUtcTime)) {
      const currentUtcTimestamp: number = this.getUtcTimestamp(this.getCurrentUtcDate());
      const selectedUtcTime: number = this.getUtcTimestamp(selectedDate);
      const selectedUtcWithBuffer = selectedUtcTime + 5000;
  
      if (selectedUtcWithBuffer < currentUtcTimestamp) {
        return true;
      }
    }

    return false;
  }

  shiftLocalDateByUtcDifference(localDate: Date, currentUtc: Date, lastUtc: Date): RdeaDate {
    const lastLocalDate: moment.Moment = moment(localDate);
    const lastUtcDate: moment.Moment = moment(lastUtc);
    const currentUtcDate: moment.Moment = moment(currentUtc);

    const difference: number = this.getDifference(lastUtcDate, currentUtcDate);
    lastLocalDate.add(difference, 'milliseconds');
    return new RdeaDate(lastLocalDate.toDate());
  }

  private getDifference(startDate: moment.Moment, endDate: moment.Moment): number {
    const date1 = cloneDeep(startDate);
    const date2 = cloneDeep(endDate);

    return date2.diff(date1);
  }

  private isDatesEquel(todayDate: Date, selectedDate: Date): boolean {
    if (todayDate.getFullYear() === selectedDate.getFullYear() &&
      todayDate.getMonth() === selectedDate.getMonth() &&
      todayDate.getDate() === selectedDate.getDate()
    ) {
      return true;
      } 

    return false;
  }
}
