import { Component } from '@angular/core';
import { MailingResponse } from '@app/views/abonents/models';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { cloneDeep } from 'lodash';
import { MailingHelperService } from '@app/views/abonents/services/mailing-helper.service';

@Component({
  selector: 'app-mailing-page',
  templateUrl: './mailing-page.component.html',
  styleUrls: ['./mailing-page.component.scss']
})
export class MailingPageComponent {
  mailing: MailingResponse;
  selectedMailingIdx: number;
  shouldRefreshMailingList: {refresh: boolean};

  constructor(
    private mailingService: MailingHelperService,
    private resolutionService: ResolutionService
  ) { }

  onExit() {
    this.mailing = null;
    this.selectedMailingIdx = null;
  }

  onSelectMailing(event: { mailing: MailingResponse, idx: number }) {
    this.selectedMailingIdx = event.idx;
    this.mailing = event.mailing;
  }

  onRepeatMailing(event: { mailing: MailingResponse }) {
    this.selectedMailingIdx = -1;
    this.mailing = this.mailingService.initEmptyMailingResponse();

    this.mailing.message = event.mailing.message;
    this.mailing.user = event.mailing.user;
    this.mailing.addressCriteria = cloneDeep(event.mailing.addressCriteria);
    this.mailing.recipientsCount = event.mailing.recipientsCount;
    this.mailing.createdAt = event.mailing.createdAt;
    this.mailing.startTime = event.mailing.startTime;
    this.mailing.providers = cloneDeep(event.mailing.providers);
  }

  onSubmit() {
    this.mailing = null;
    this.selectedMailingIdx = null;
  }

  onDeleteSuccess() {
    this.mailing = null;
    this.selectedMailingIdx = null;
    this.shouldRefreshMailingList = {refresh: true};
  }

  isMobile(): boolean {
    return this.resolutionService.isMobile;
  }
}
