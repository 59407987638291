import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable, of } from "rxjs";
import { HlsInfo, HlsManifestItems, TypeReceiver } from "../models/hls.model";
import { RdeaHls, TZ_OFFSET_IN_MINUTES } from "@app/shared/components/video-player";
import { LocalStorageGeneralKey, LocalStorageHelper, LoggerService } from "@app/shared/entities";
import { MediaService } from "../media.service/media.service";
import { IVideoConfigHls } from "../models/video.models";
import { StoreService } from "../service/video-store/store.service";
import { catchError, mergeMap, take, tap } from "rxjs/operators";
import { BufferAppendedData, BufferAppendingData, BufferCodecsData, BufferCreatedData, BufferEOSData, BufferFlushedData, BufferFlushingData, ErrorData, ErrorTypes, Events, FragBufferedData, FragChangedData, FragDecryptedData, FragLoadEmergencyAbortedData, FragLoadingData, Fragment, FragParsedData, FragParsingInitSegmentData, FragParsingMetadataData, FragParsingUserdataData, LevelLoadedData, LevelLoadingData, LevelPTSUpdatedData, LevelSwitchedData, LevelSwitchingData, LevelUpdatedData, ManifestLoadedData, ManifestLoadingData, ManifestParsedData, MediaAttachingData, SubtitleFragProcessedData, SubtitleTracksUpdatedData, SubtitleTrackSwitchData, TrackLoadedData, TrackLoadingData } from 'hls.js';
import { HlsEventsService } from "./hls-events.service";
import { takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { RdvaApiService, RdvaPlaylistItem } from "@app/shared/entities/integrations";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class HlsConnectionService {
  private hlsInfoListSubject = new BehaviorSubject<HlsInfo[]>([]);
  hlsInfoList$ = this.hlsInfoListSubject.asObservable();

  constructor(
    private mediaService: MediaService,
    private storeService: StoreService,
    private loggerService: LoggerService,
    private hlsEventsService: HlsEventsService,
    private rdvaApiService: RdvaApiService
  ) { }

  init(config: IVideoConfigHls): void {
    const hls = this.buildHls()

    this.addHlsInfo(hls, config.id)

    this.mediaService.addMedia({
      id: config.id,
      connectMedia: (element: HTMLVideoElement): void => {
        hls.attachMedia(element)
      }
    })

    this.hlsEventsService.enableDataErrorEventsListener(hls);
    
    if (config.type === TypeReceiver.LIVE) {
      this.loadPlaylists(hls, config.hostUri, config.id);
    }

    if (config.type === TypeReceiver.ARCHIVE) {
      this.subscribeGetArchivePlaylist(config.hostUri, config.id);
      this.subscribeChangeArchivePlaylist(config.id);
      this.hlsEventsService.initManifestLoadedDataListener(hls)
    }

    if (this.loggerService.enabled) {
      this.hlsEventsService.enableDebugEventsListeners(hls);
    }

  }

  destroyHls(id: number): void {
    const { hls } = this.findHlsInfo(id);
    hls.destroy();

    const filteredHlsInfoList = this.filterHlsInfo(id);
    this.hlsInfoListSubject.next(filteredHlsInfoList);

    this.mediaService.removeMedia(id);
  }

  destroy(): void {
    this.hlsInfoListSubject.value
      .forEach(value => value.hls.destroy());
    this.hlsInfoListSubject.next([]);
  }

  private restartHlsForPlaylist(id: number, playlist: string): void {
    this.destroyHls(id)

    const hls = this.buildHls()
    this.addHlsInfo(hls, id)
    this.mediaService.addMedia({
      id,
      connectMedia: (element: HTMLVideoElement): void => {
        hls.attachMedia(element)
      }
    })
    hls.loadSource(playlist);
    this.hlsEventsService.initManifestLoadedDataListener(hls)
  }

  private addHlsInfo(hls: RdeaHls, id: number): void {
    const hlsInfo: HlsInfo = {
      id,
      hls
    }

    const currentList = this.hlsInfoListSubject.value;
    this.hlsInfoListSubject.next([...currentList, hlsInfo])
  }

  private findHlsInfo(id: number): HlsInfo {
    return this.hlsInfoListSubject.value
      .find(value => value.id == id)
  }

  private filterHlsInfo(id: number): HlsInfo[] {
    return this.hlsInfoListSubject.value
      .filter(value => value.id !== id);
  }

  private buildHls(): RdeaHls {
    const token = LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN);

    return new RdeaHls(token, {
      autoStartLoad: true,
      maxBufferHole: 10,
      liveDurationInfinity: true,
      startFragPrefetch: true,
      manifestLoadingTimeOut: 10000,
      manifestLoadingMaxRetry: 5,
    });
  }

  private loadPlaylists(hls: RdeaHls, hostUri: string, id: number) {
    const source = this.getHlsLink(hostUri, id)
    hls.loadSource(source)
  }

  private getHlsLink(hostUri: string, id: number): string {
    return `https://s.${hostUri}/live/${id}.m3u8`;
  }

  private getPlaylistEdges(playlistItems: RdvaPlaylistItem[]): Observable<HlsManifestItems> {
    const [firstPlaylist, lastPlaylist] = [playlistItems[0].playlist, playlistItems[playlistItems.length - 1].playlist];

    const firstPlaylistObservable = this.rdvaApiService.getPlaylist(firstPlaylist)
    const lastPlaylistObservable = this.rdvaApiService.getPlaylist(lastPlaylist)

    return forkJoin([firstPlaylistObservable, lastPlaylistObservable])
  }

  private subscribeGetArchivePlaylist(hostUri: string, id: number): void {
    this.rdvaApiService.getPlaylists(hostUri, id, -TZ_OFFSET_IN_MINUTES)
      .pipe(
        tap(playlistItems => {
          this.storeService.updatePlayListItems(playlistItems)
          const defaultPlaylistItem = playlistItems[playlistItems.length - 1]
          this.storeService.updateCurrentPlaylist(defaultPlaylistItem)
        }),
        mergeMap((response) => this.getPlaylistEdges(response)),
        tap(manifestItems => this.storeService.updatePlaylistHlsManifestItems(manifestItems)),
        catchError((err: HttpErrorResponse) => {
          this.storeService.fireHlsPlaylistLoadError(err)
          return of(null);
        }),
        takeUntilDestroyed(this, { destroyMethod: this.destroy })
      )
      .subscribe()
  }

  private subscribeChangeArchivePlaylist(id: number): void {
    this.storeService.selectCurrentPlaylist$()
      .pipe(takeUntilDestroyed(this, { destroyMethod: this.destroy }))
      .subscribe(value => this.restartHlsForPlaylist(id, value.playlist))
  }
}
