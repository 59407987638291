import {
  IntercomType,
  IntercomTypeGeneratorParams,
  IntercomTypeGeneratorUrls
} from '@app/shared/entities/rd/intercom-type/models';
import { ProtocolTypes } from '@app/shared/entities/rd/protocols/models';
import * as md5 from 'md5';

export class IntercomTypeLinkGenerator {
  private static defaultUrlTemplates = {
    DEFAULT: {
      baseUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.login}:${params.password}@${params.ip}:${params.port}/cgi-bin`;
      },
      livenessUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.defaultUrlTemplates.DEFAULT.baseUrl(
          params
        )}/sip_cgi?action=regstatus`;
      },
      openDoorUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.defaultUrlTemplates.DEFAULT.baseUrl(
          params
        )}/intercom_cgi?action=maindoor`;
      }
    },

    [ProtocolTypes.Sputnik]: {
      baseUrl: (params: IntercomTypeGeneratorParams) => {
        return `https://api.sputnik.systems/api/v1/account/devices/intercoms`;
      },
      livenessUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.defaultUrlTemplates[
          ProtocolTypes.Sputnik
        ].baseUrl(params)}/${params.ip}`;
      },
      bearerToken: (params: IntercomTypeGeneratorParams) => {
        return `${params.bearerToken}`;
      },
      openDoorUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.defaultUrlTemplates[
          ProtocolTypes.Sputnik
        ].baseUrl(params)}/${params.ip}/open_door`;
      }
    },

    [ProtocolTypes.BAS_IP]: {
      baseUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.ip}:${params.port}/api/v0`;
      },
      livenessUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.defaultUrlTemplates[
          ProtocolTypes.BAS_IP
        ].baseUrl(params)}/sip/status`;
      },
      authUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.defaultUrlTemplates[
          ProtocolTypes.BAS_IP
        ].baseUrl(params)}/login?username=${params.login}&password=${md5(
          params.password
        )}`;
      },
      openDoorUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.defaultUrlTemplates[
          ProtocolTypes.BAS_IP
        ].baseUrl(params)}/access/general/lock/open/remote/accepted/1`;
      }
    },

    [ProtocolTypes.QTECH]: {
      baseUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.ip}:${params.port}/fcgi`;
      },
      livenessUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.defaultUrlTemplates[
          ProtocolTypes.QTECH
        ].baseUrl(params)}/intercom`;
      },
      openDoorUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.defaultUrlTemplates[
          ProtocolTypes.QTECH
        ].baseUrl(params)}/do?action=OpenDoor&UserName=${
          params.login
        }&Password=${params.password}&DoorNum=${params.doorNum}`;
      }
    },
    [ProtocolTypes.Sokol]: {
      baseUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.login}:${params.password}@${params.ip}:${params.port}`;
      },
      livenessUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.defaultUrlTemplates[
          ProtocolTypes.Sokol
        ].baseUrl(params)}/system/info`;
      },
      openDoorUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.defaultUrlTemplates[
          ProtocolTypes.Sokol
        ].baseUrl(params)}/relay/${params.doorNum}/open`;
      }
    },
    [ProtocolTypes.Hikvision]: {
      openDoorUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.ip}:${params.port}/ISAPI/AccessControl/RemoteControl/door/${params.doorNum}`;
      },
      livenessUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.ip}:${params.port}/ISAPI/System/deviceInfo`;
      }
    },
    [ProtocolTypes.Rubitek]: {
      openDoorUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.login}:${params.password}@${params.ip}:${params.port}/api/v1/doors/${params.doorNum}/open`;
      },
      livenessUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.login}:${params.password}@${params.ip}:${params.port}/api/v1/configuration`;
      }
    }
  };

  private static specificUrlTemplates = {
    [ProtocolTypes.BEWARD + 'Beward; DS06AP-3LK']: {
      baseUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.login}:${params.password}@${params.ip}:${params.port}/cgi-bin`;
      },
      livenessUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.specificUrlTemplates[
          ProtocolTypes.BEWARD + 'Beward; DS06AP-3LK'
        ].baseUrl(params)}/sip_cgi?action=regstatus`;
      },
      openDoorUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.ip}:${params.port}/cgi-bin/alarmout_cgi?Output=0&Status=1&user=${params.login}&pwd=${params.password}`;
      }
    },
    [ProtocolTypes.Hikvision + 'Hikvision DS-KD8013-IME6']: {
      baseUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.ip}:${params.port}/ISAPI`;
      },
      openDoorUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.specificUrlTemplates[
        ProtocolTypes.Hikvision + 'Hikvision DS-KD8013-IME6'
          ].baseUrl(params)}/AccessControl/RemoteControl/door/${params.doorNum}`;
      },
      livenessUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.specificUrlTemplates[
        ProtocolTypes.Hikvision + 'Hikvision DS-KD8013-IME6'
          ].baseUrl(params)}/System/deviceInfo`;
      }
    },
    [ProtocolTypes.Rubitek + 'Rubetek RV-3434']: {
      baseUrl: (params: IntercomTypeGeneratorParams) => ``,
      livenessUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.ip}:${params.port}/api/v1/intercom/check_status`;
      },
      openDoorUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.login}:${params.password}@${params.ip}:${params.port}/api/v1/doors/${params.doorNum}/open`;
      },
    }, 
    [ProtocolTypes.UfanetSecretTop + 'Ufanet Secret Top T3 API']: {
      baseUrl: (params: IntercomTypeGeneratorParams) => {
        return `http://${params.login}:${params.password}@${params.ip}:${params.port}/api/v1`;
      },
      openDoorUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.specificUrlTemplates[
          ProtocolTypes.UfanetSecretTop + 'Ufanet Secret Top T3 API'
        ].baseUrl(params)}/doors/${params.doorNum}/open`;
      },
      livenessUrl: (params: IntercomTypeGeneratorParams) => {
        return `${IntercomTypeLinkGenerator.specificUrlTemplates[
          ProtocolTypes.UfanetSecretTop + 'Ufanet Secret Top T3 API'
        ].baseUrl(params)}/status`;
      }
    }

  };

  static generateIntercomTypeLinks(
    intercomType: IntercomType,
    params: IntercomTypeGeneratorParams,
    keys: string[]
  ): Partial<IntercomTypeGeneratorUrls> {
    const response: Partial<IntercomTypeGeneratorUrls> = {};
    const protocolNumber: number = intercomType.protocol.number;
    let protocolType: ProtocolTypes | 'DEFAULT';
    let templates;
    let intercomName = '';

    if (
      IntercomTypeLinkGenerator.specificUrlTemplates[
        protocolNumber + intercomType.name
      ]
    ) {
      protocolType = protocolNumber;
      templates = IntercomTypeLinkGenerator.specificUrlTemplates;
      intercomName = intercomType.name;
    } else {
      if (
        [
          ProtocolTypes.QTECH,
          ProtocolTypes.Akuvox,
          ProtocolTypes.Rubetek
        ].includes(protocolNumber)
      ) {
        protocolType = ProtocolTypes.QTECH;
      } else if (
        IntercomTypeLinkGenerator.defaultUrlTemplates[protocolNumber]
      ) {
        protocolType = protocolNumber;
      } else {
        protocolType = 'DEFAULT';
      }
      templates = IntercomTypeLinkGenerator.defaultUrlTemplates;
    }

    keys.forEach((key) => {
      if (templates[protocolType + intercomName][key.toString()]) {
        response[key] =
          templates[protocolType + intercomName][key.toString()](params);
      }
    });

    if (protocolNumber === ProtocolTypes.Hikvision || protocolNumber === ProtocolTypes.Rubitek) {
      response.login = params.login;
      response.password = params.password;
    }

    return response;
  }
}
