import { HttpErrorResponse } from '@angular/common/http';
import { MailingBatchesResponse, MailingRequest, MailingResponse } from '@app/views/abonents/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  GetMailings = '[Abonents] GetMailings',
  GetMailingsSuccess = '[Abonents] GetMailings Success',
  GetMailingsFailure = '[Abonents] GetMailings Failure',
  GetMailingsClear = '[Abonents] GetMailings Clear',

  SendMailing = '[Abonents] SendMailing',
  SendMailingSuccess = '[Abonents] SendMailing Success',
  SendMailingFailure = '[Abonents] SendMailing Failure',
  SendMailingClear = '[Abonents] SendMailing Clear',

  GetMailingBatches = '[Abonents] GetMailingBatches',
  GetMailingBatchesSuccess = '[Abonents] GetMailingBatches Success',
  GetMailingBatchesFailure = '[Abonents] GetMailingBatches Failure',

  DeleteMailing = '[Abonents] DeleteMailing',
  DeleteMailingSuccess = '[Abonents] DeleteMailing Success',
  DeleteMailingFailure = '[Abonents] DeleteMailing Failure',
}

export class GetMailings implements Action {
  readonly type = ActionTypes.GetMailings;

  constructor(public isForScrollingFeed: boolean, public page?: number, public size?: number) { }
}

export class GetMailingsSuccess implements Action {
  readonly type = ActionTypes.GetMailingsSuccess;

  constructor(public isForScrollingFeed: boolean, public payload: MailingResponse[], public totalCount: number) { }
}

export class GetMailingsFailure implements Action {
  readonly type = ActionTypes.GetMailingsFailure;

  constructor(public payload: HttpErrorResponse) { }
}

export class GetMailingsClear implements Action {
  readonly type = ActionTypes.GetMailingsClear;

  constructor() { }
}

export class SendMailing implements Action {
  readonly type = ActionTypes.SendMailing;

  constructor(public mailing: MailingRequest) { }
}

export class SendMailingSuccess implements Action {
  readonly type = ActionTypes.SendMailingSuccess;

  constructor(public mailing: MailingResponse) { }
}

export class SendMailingFailure implements Action {
  readonly type = ActionTypes.SendMailingFailure;

  constructor(public payload: HttpErrorResponse) { }
}

export class SendMailingClear implements Action {
  readonly type = ActionTypes.SendMailingClear;

  constructor() { }
}

export class GetMailingBatches implements Action {
  readonly type = ActionTypes.GetMailingBatches;

  constructor(public mailingId: number, public hasDelay: boolean) { }
}

export class GetMailingBatchesSuccess implements Action {
  readonly type = ActionTypes.GetMailingBatchesSuccess;

  constructor(public payload: MailingBatchesResponse[]) { }
}

export class GetMailingBatchesFailure implements Action {
  readonly type = ActionTypes.GetMailingBatchesFailure;

  constructor(public payload: HttpErrorResponse) { }
}

export class DeleteMailing implements Action {
  readonly type = ActionTypes.DeleteMailing;

  constructor(public mailingId: number) { }
}

export class DeleteMailingSuccess implements Action {
  readonly type = ActionTypes.DeleteMailingSuccess;

  constructor(public payload: {mailingDeleted: boolean}) { }
}

export class DeleteMailingFailure implements Action {
  readonly type = ActionTypes.DeleteMailingFailure;

  constructor() {}
}

export type Actions =
  | GetMailings
  | GetMailingsSuccess
  | GetMailingsFailure
  | GetMailingsClear
  | SendMailing
  | SendMailingSuccess
  | SendMailingFailure
  | SendMailingClear

  | GetMailingBatches
  | GetMailingBatchesSuccess
  | GetMailingBatchesFailure
  | DeleteMailing
  | DeleteMailingSuccess
  | DeleteMailingFailure;
