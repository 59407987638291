import { Component, OnInit } from '@angular/core';
import { PaymentConfigurationResponse } from '@app/shared/entities/rd';
import { NavbarLink } from '@app/shared/ui';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { PaymentsPageType } from '../../../models';
import { PaymentsService } from '../../../services';
import { PaymentsPageStore } from '../../../store';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-payments-page',
  templateUrl: './payments-page.component.html',
  styleUrls: ['./payments-page.component.scss'],
  providers: [PaymentsPageStore, PaymentsService]
})
export class PaymentsPageComponent implements OnInit {
  public readonly pageType: typeof PaymentsPageType = PaymentsPageType;
  public readonly navbarLinks: NavbarLink[] = [
    {
      name: PaymentsPageType.PAYMENTS,
      text: this.translate.instant('payments.page.navbar.payments')
    },
    {
      name: PaymentsPageType.SETTINGS,
      text: this.translate.instant('payments.page.navbar.settings')
    },
      {
      name: PaymentsPageType.UPLOAD,
      text: this.translate.instant('payments.page.navbar.upload')
    }
  ];

  public readonly configuration$: Observable<PaymentConfigurationResponse | null> =
    this.store.configuration$;
  public readonly integrationCheck$: Observable<boolean> =
    this.store.integrationCheck$;
  public readonly errorHandled$: Observable<boolean> = this.store.errorHandled$;

  public activeLink: string = PaymentsPageType.PAYMENTS;

  constructor(
    private store: PaymentsPageStore,
    private paymentsService: PaymentsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.paymentsService.companyShortName$
      .pipe(
        filter((shortName: string) => shortName !== null),
        take(1),
        tap((companyShortName: string) =>
          this.store.checkPaymentConnection(companyShortName)
        )
      )
      .subscribe();

    this.paymentsService.loadCompany();
  }

  public onChangeTab(tabIdx: number): void {
    this.activeLink = this.navbarLinks[tabIdx].name;
  }
}
