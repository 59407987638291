import { HttpErrorResponse } from "@angular/common/http";
import { ErrorData } from "hls.js";
import { Option } from "./select.model";

export interface PeerConnectionState {
  type: StateEnum,
  state: string
}

export enum StateEnum {
  iceConnectionState = 'iceConnectionState',
  signalingState = 'signalingState',
  connectionState = 'connectionState',
}

export type Event = IEvent[keyof IEvent];
export interface IEvent {
  selectedPlaylistPlayer: Option,
  newPlayerCurrentTime: number,
  newPlayerState: PlayerStateEnum,
  selectedDateTime: number,
  requestPictureInPicture: null,
  volumeChange: number,
  mutedChange: boolean,
  hlsConnectionError: ErrorData,
  hlsPlaylistLoadError: HttpErrorResponse,
}

type EventBase<TType, TValue> = {
  type: TType;
  value: TValue;
};

export enum PlayerStateEnum {
  PLAY = 'play',
  PAUSE = 'pause',
  LOAD = 'load'
}

