import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MailingStatus } from '@app/views/abonents/models/mailing/mailing-status.model';
import { ProgressBarModule } from 'primeng/progressbar';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  standalone: true,
  imports: [
    ProgressBarModule,
    ReactiveFormsModule,
    CommonModule
  ]
})
export class ProgressBarComponent {
  @Input() value: number | null;
  colorClass = 'grey-progress-bar';

  @Input() set mailingStatus(status: MailingStatus) {
    if (status === MailingStatus.INPROGRESS) {
      this.colorClass = 'orange-progress-bar';
      return;
    }

    if (status === MailingStatus.COMPLETED) {
      this.colorClass = 'green-progress-bar';
      return;
    }

    this.colorClass = 'grey-progress-bar';
  }

}
