<div class="outer-container" [ngClass]="{ 'container': fromNavigationState }">
  <mat-card class="card-container">
    <mat-card-title *ngIf="mailing">
      {{
        mailing.id
          ? ('mailing.page.new.title.from' | translate: { date: (mailing.createdAt | date: 'dd.MM.yyyy') })
          : ('mailing.page.new.title.create' | translate)
      }}
    </mat-card-title>

    <mat-card-content [ngClass]="{ 'empty-content': !mailing }">
      <div *ngIf="mailing">
        <div *ngIf="mailing.id">
          <div class="mail-status">
            <app-mailing-status 
              [mailingId]="mailing.id"
              [mailingSender]="mailing.user"
              [recipientsCount]="mailing.recipientsCount"
              [startTime]="mailing.startTime"
              [createdTime]="mailing.createdAt"
              (canDeleteMailing)="onCanDelete($event)"
            >
            </app-mailing-status>
          </div>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ 'mailing.page.new.header.added_addresses' | translate }}
              </mat-panel-title>
              <mat-panel-description>
                {{ addedAddresses.length }}
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-chip-list *ngIf="addedAddresses.length > 0; else emptyAddresses">
              <mat-chip
                *ngFor="let addedAddress of addedAddresses; let idx = index"
                [selectable]="true"
                [removable]="false"
              >
                {{ addedAddress.displayedName }}
              </mat-chip>
            </mat-chip-list>
            <ng-template #emptyAddresses>
              <p>
                {{ 'mailing.page.new.message.empty_addresses' | translate }}
              </p>
            </ng-template>
          </mat-expansion-panel>

          <div class="mailing-body-footer">
            <div class="mailing-body">
            <mat-form-field class="message-area remove-bottom-padding disable-color" appearance="outline">
              <mat-label>
                {{ 'mailing.page.new.field.message' | translate }}
              </mat-label>
              <textarea
                matInput 
                cdkTextareaAutosize
                cdkAutosizeMinRows="7"
                type="text"
                [disabled]="true"
                [value]="mailing.message"
              ></textarea>
            </mat-form-field>
            </div>


            <div class="buttons">
              <button 
                *ngIf="canDelete"
                mat-raised-button
                color="warn-inverse"
                class="delete-btn"
                (click)="onDeleteMailing()"
              >
                {{ 'mailing.page.new.button.delete' | translate }}
              </button>
              <button
                mat-raised-button
                color="primary"
                [ngClass]="{'repeat-btn-margin-left': canDelete}"
                (click)="onRepeatMailing()"
              >
                {{ 'mailing.page.new.button.repeat' | translate }}
              </button>
            </div>
          </div>
        </div>
        
        <div *ngIf="!mailing.id">
          <div class="address-block input-field">
            <div>
              <mat-form-field class="address-input none-padding none-margin" appearance="outline">
                <mat-label>
                  {{ 'mailing.page.new.field.address' | translate }}
                </mat-label>
                <input
                  #inputAutoComplete
                  type="text"
                  matInput
                  placeholder="{{ 'mailing.page.new.placeholder.address' | translate }}"
                  [formControl]="filterInput"
                  [matAutocomplete]="auto"
                  autocomplete="off"
                  spellcheck="false"
                  autocorrect="off"
                  autocapitalize="off"
                />
                <mat-error>
                  {{ 'mailing.page.new.error.address' | translate }}
                </mat-error>
              </mat-form-field>

              <mat-autocomplete 
                #auto="matAutocomplete" 
                [displayWith]="displayFn"
                (optionSelected)="onSelected($event.option.value)"
              >
                <mat-option *ngFor="let address of filteredAddresses" [value]="address">
                  {{ address.displayedName }}
                </mat-option>
              </mat-autocomplete>
            </div>
            <button
              [disabled]="!addressSelected.cities.selectedId"
              mat-raised-button
              color="primary-inverse"
              (click)="onAddAddress()"
              class="add-address-button"
            >
              {{ 'mailing.page.new.button.add' | translate }}
            </button>
          </div>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ 'mailing.page.new.header.added_addresses' | translate }}
              </mat-panel-title>
              <mat-panel-description>
                {{ addedAddresses.length }}
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-chip-list *ngIf="addedAddresses.length > 0; else emptyAddresses">
              <mat-chip *ngFor="let addedAddress of addedAddresses; let idx = index" [selectable]="false"
                [removable]="true">
                {{addedAddress.displayedName}}
                <mat-icon matChipRemove *ngIf="true" (click)="onRemoveAddress(idx)">cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
            <ng-template #emptyAddresses>
              <p>
                {{ 'mailing.page.new.message.empty_addresses' | translate }}
              </p>
            </ng-template>
          </mat-expansion-panel>

          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <mat-form-field class="message-area" appearance="outline">
              <mat-label>
                {{ 'mailing.page.new.field.message' | translate }}
              </mat-label>
              <textarea
                matInput cdkTextareaAutosize
                cdkAutosizeMinRows="7"
                cdkAutosizeMaxRows="7"
                placeholder="{{ 'mailing.page.new.placeholder.message' | translate }}"
                type="text"
                formControlName="message"
                autocomplete="off"
                spellcheck="false"
                autocorrect="off"
                autocapitalize="off"
                required
              ></textarea>
              <mat-error>
                {{ 'mailing.page.new.error.message' | translate }}
              </mat-error>
            </mat-form-field>

            <div class="mailing-params">
              <div class="mailing-params__block">
                <div class="mailing-params__title">
                  {{ 'mailing.page.new.field.mobile_providers_block_title' | translate }}
                </div>

                <div class="mailing-params__controls" formGroupName="mobilePlatforms">
                  <mat-checkbox class="ios-provider" formControlName="apple"> 
                    <span class="checkbox-label">
                      {{ 'mailing.page.new.field.ios_providers' | translate }}
                    </span>
                  </mat-checkbox>

                  <mat-checkbox formControlName="google"> 
                    <span class="checkbox-label">
                      {{ 'mailing.page.new.field.android_providers' | translate }}
                    </span>
                  </mat-checkbox>

                  <mat-checkbox formControlName="huawei"> 
                    <span class="checkbox-label">
                      {{ 'mailing.page.new.field.huawei_providers' | translate }}
                    </span>
                  </mat-checkbox>
                </div>
              </div>
            </div>

            <div class="mailing-methods">
              <div class="mailing-methods__title">
                {{ 'mailing.page.new.field.sending_method_block_title' | translate }}
              </div>

              <div class="mailing-methods__block">
                <div class="mailing-methods__block-method">
                  <p-radioButton 
                    [inputId]="'mailing-method-standart'" 
                    [value]="mailingMethod.STANDART"
                    formControlName="method"
                  />
                  <label [for]="'mailing-method-standart'">
                    <span class="radio-button-label">
                      {{ 'mailing.page.new.field.standart_sending_method' | translate }}
                    </span>
                  </label>
                </div>
                
                <div class="mailing-methods__block-method">
                  <p-radioButton 
                    [inputId]="'mailing-method-scheduled'" 
                    [value]="mailingMethod.SCHEDULED"
                    formControlName="method"
                  />
                  <label [for]="'mailing-method-scheduled'">
                    <span class="radio-button-label">
                      {{ 'mailing.page.new.field.scheduled_sending_method' | translate }}
                    </span>
                  </label>
                </div>
              </div>

              <div 
                class="mailing-methods__scheduled" 
                [hidden]="methodFieldValue !== mailingMethod.SCHEDULED"
              >
                <div class="mailing-methods__scheduled-form" [formGroup]="scheduledDataForm">
                  <span class="mailing-methods__scheduled-text scheduled-title">
                    {{ 'mailing.page.new.field.send_at' | translate }}
                  </span>
                  <p-calendar 
                    class="mailing-methods__scheduled__date-picker picker font-text14" 
                    inputId="calendar" 
                    [minDate]="calendarMinDate"
                    [maxDate]="calendarMaxDate"
                    formControlName="date"
                  />
                  <span  class="mailing-methods__scheduled-text">
                    {{ 'mailing.page.new.field.in' | translate }}
                  </span>
                  <p-calendar 
                    class="mailing-methods__scheduled__time-picker picker font-text14" 
                    inputId="time-picker"
                    [timeOnly]="true"
                    formControlName="time"
                  />
  
                  <span  class="mailing-methods__scheduled-text hint">
                    ({{ 'mailing.page.new.field.utc_time' | translate }})
                  </span>
                </div>
                
                <div class="mailing-methods__scheduled-text hint">
                  {{ 'mailing.page.new.field.text_hint_user_time' | translate }}
                  {{ currentLocalDate | date: 'dd.MM.yyyy'}}
                  {{ currentLocalTime | date: 'HH:mm:ss'}}
                  ({{ currentLocalDate?.getTimezoneName() ?? currentLocalTime?.getTimezoneName() }})
                </div>
              </div>
            </div>

            <ng-container *ngIf="methodFieldValue === mailingMethod.STANDART else scheduled">
              <button type="submit" mat-raised-button color="primary">
                <span *ngIf="methodFieldValue === mailingMethod.STANDART else scheduled">
                  {{ 'mailing.page.new.button.submit' | translate }}
                </span>
                </button>
            </ng-container>


              <ng-template #scheduled>
                <button type="submit" mat-raised-button color="primary" [disabled]="!scheduledDataForm.valid">
                  <span>
                    {{ 'mailing.page.new.button.scheduled' | translate }}
                  </span>
                </button>
              </ng-template>
          </form>
        </div>
      </div>
      
      <div *ngIf="this.mailing == null" class="not-selected-text">
        {{ 'mailing.page.new.message.not_selected' | translate }}
      </div>
    </mat-card-content>
  </mat-card>
</div>
