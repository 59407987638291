import { Injectable } from '@angular/core';
import { MailingBatchesResponse, MailingResponse } from '@app/views/abonents/models';
import { MailingApiService } from '@app/views/abonents/services';
import { PagedResponse } from '@app/shared/models';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, delay, map, switchMap, tap } from 'rxjs/operators';
import { ActionTypes, GetMailingBatches, DeleteMailing, GetMailingBatchesSuccess, GetMailings, GetMailingsClear, GetMailingsFailure, GetMailingsSuccess, SendMailing, SendMailingClear, SendMailingFailure, SendMailingSuccess, DeleteMailingSuccess, GetMailingBatchesFailure, DeleteMailingFailure } from '../actions/mailing.actions';
import { SnackbarService } from '@app/shared/components/snackbar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MailingEffects {
  @Effect()
  GetMailings$ = this.actions$.pipe(
    ofType<GetMailings>(ActionTypes.GetMailings),
    switchMap(action =>
      this.mailingService.getMailings(action.page, action.size).pipe(
        map((mailings: PagedResponse<MailingResponse>) => new GetMailingsSuccess(
          action.isForScrollingFeed, 
          mailings.content, 
          mailings.totalElements
        )),
        catchError(error => of(new GetMailingsFailure(error)))
      )
    )
  );


  @Effect({ dispatch: false })
  GetMailingsClear$ = this.actions$.pipe(
    ofType<GetMailingsClear>(ActionTypes.GetMailingsClear),
    tap(() => new GetMailingsClear())
  );


  @Effect()
  SendMailing$ = this.actions$.pipe(
    ofType<SendMailing>(ActionTypes.SendMailing),
    concatMap(action =>
      this.mailingService.sendMailing(action.mailing).pipe(
        map(mailing => new SendMailingSuccess(mailing)),
        catchError(error => of(new SendMailingFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  SendMailingClear$ = this.actions$.pipe(
    ofType<SendMailingClear>(ActionTypes.SendMailingClear),
    tap(() => new SendMailingClear())
  );

  GetMailingBatches$ = createEffect(() => 
    this.actions$.pipe(
      ofType<GetMailingBatches>(ActionTypes.GetMailingBatches),
      switchMap(action => 
        this.mailingService.getMailingBatches(action.mailingId).pipe(
          delay(action.hasDelay ? 1500 : 500),
          map((batches: MailingBatchesResponse[]) => new GetMailingBatchesSuccess(batches)),
        )
      ), 
      catchError(error => {
        this.snackbar.showMessage(this.translate.instant('mailing.page.new.message.get_mailing_batches.failed'), 'error');
        return of(new GetMailingBatchesFailure(error))
      })
    )
  )

  DeleteMailing$ = createEffect(() => 
    this.actions$.pipe(
      ofType<DeleteMailing>(ActionTypes.DeleteMailing),
      switchMap(action => 
        this.mailingService.deleteMailing(action.mailingId).pipe(
          map(() => {
            this.snackbar.showMessage(this.translate.instant('mailing.page.new.message.delete.success'), 'success');
            return new DeleteMailingSuccess({mailingDeleted: true});
          }),
        )
      ),
      catchError(() => {
        this.snackbar.showMessage(this.translate.instant('mailing.page.new.message.delete.failed'), 'error');
        return of(new DeleteMailingFailure())
      })
    )
  )

  constructor(
    private actions$: Actions,
    private mailingService: MailingApiService, 
    private snackbar: SnackbarService,
    private translate: TranslateService,
  ) { }
}
