import { AfterContentInit, Component, ContentChild, ElementRef, Input } from "@angular/core";
import { RdVideoService } from "../../service/video.service";
import { DecorateUntilDestroy, takeUntilDestroyed } from "@app/shared/rxjs/operator/take-until-destroyed";
import { StoreService } from "../../service/video-store/store.service";
import { distinctUntilChanged, map } from "rxjs/operators";
import { PlayerStateEnum } from "../../models/state.model";

@DecorateUntilDestroy()
@Component({
  selector: 'rd-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class RdVideoComponent implements AfterContentInit {
  @Input() streamId: number;
  @ContentChild('video') videoElementRef: ElementRef<HTMLVideoElement>;

  constructor(private rdVideoService: RdVideoService, private storeService: StoreService) { }

  ngAfterContentInit(): void {
    const elementIsMuted = this.videoElementRef.nativeElement.muted;
    this.storeService.updatePlayerIsMuted(elementIsMuted)

    const elementVolume = this.videoElementRef.nativeElement.volume;
    this.storeService.updatePlayerVolume(elementVolume)

    this.rdVideoService.mediaStreamList$
      .pipe(takeUntilDestroyed(this))
      .subscribe(list => {
        list.forEach(streamInfo => {
          if (this.streamId === streamInfo.id) {
            streamInfo.connectMedia(this.videoElementRef.nativeElement);
          }
        })
      })

    this.storeService.onNewPlayerCurrentTime$()
      .pipe(
        map(value => value),
        distinctUntilChanged(),
        takeUntilDestroyed(this)
      )
      .subscribe((value) => {
        this.videoElementRef.nativeElement.currentTime = Math.ceil(value);
      })

    this.storeService.onVolumeChange$()
      .pipe(takeUntilDestroyed(this))
      .subscribe((value) => {
        this.storeService.updatePlayerVolume(value)
        this.videoElementRef.nativeElement.volume = value
      })
    
    this.storeService.onMutedChange$()
      .pipe(takeUntilDestroyed(this))
      .subscribe((value) => {
        this.storeService.updatePlayerIsMuted(value)
        this.videoElementRef.nativeElement.muted = value
      });

    this.storeService.onRequestPictureInPicture$()
      .pipe(takeUntilDestroyed(this))
      .subscribe(() => {
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture()
          this.videoElementRef.nativeElement.requestPictureInPicture();
        } else {
          this.videoElementRef.nativeElement.requestPictureInPicture();
        }
      })

    this.storeService.onNewPlayerState$()
      .pipe(takeUntilDestroyed(this))
      .subscribe((state) => {
        switch (state) {
          case PlayerStateEnum.PLAY:
            this.videoElementRef.nativeElement.play();
            break;
          case PlayerStateEnum.PAUSE:
            this.videoElementRef.nativeElement.pause();
            break;
        }
      })

    this.videoElementRef.nativeElement.addEventListener('timeupdate', this.onTimeUpdate.bind(this));
    this.videoElementRef.nativeElement.addEventListener('play', this.onPlayUpdate.bind(this));
    this.videoElementRef.nativeElement.addEventListener('pause', this.onPauseUpdate.bind(this));
    this.videoElementRef.nativeElement.addEventListener('loadstart', this.onLoadUpdate.bind(this));

    document.addEventListener('fullscreenchange', this.onFullscreenChange.bind(this));

  }

  onTimeUpdate(): void {
    const currentTime = this.videoElementRef.nativeElement.currentTime;
    this.storeService.updatePlayerCurrentTime(currentTime);
  }

  onPlayUpdate(): void {
    this.storeService.updatePlayerState(PlayerStateEnum.PLAY)
  }

  onPauseUpdate(): void {
    this.storeService.updatePlayerState(PlayerStateEnum.PAUSE)
  }

  onLoadUpdate(): void {
    this.storeService.updatePlayerState(PlayerStateEnum.LOAD)
  }

  onFullscreenChange(event): void {
    const isFullScreen = !!document.fullscreenElement;
    if(!isFullScreen){
      this.storeService.updatePlayerIsFullScreen(isFullScreen);
    }
  }
}
